.rs-team {
    &.style1 {
        .about-top-border {
            border: 0;
            width: 100%;
            height: 1px;
            background: $navColor;
            margin-left: 0;
            margin-bottom: 16px;
        }

        .about-btn--secondary {
            margin-right: 12px;
            border: 1px solid $secondaryColor;
            padding: 15px;
            margin-top: 20px;
            color: $secondaryColor;
            background: $white;
            display: flex;
            font-size: large;
            justify-content: center;
            position: relative;
            transition: 300ms all;

            &:after {
                content: "";
                position: absolute;
                position: absolute;
                width: 100%;
                height: 100%;
                /* border-right: 1px solid #334c6f; */
                /* border-bottom: 1px solid #334c6f; */
                bottom: 0;
                transform: translate(12px, 12px);
                background: rgba(51, 76, 111, 0.5);
                z-index: -1;
                transition: 300ms transform;
            }

            &:hover {
                margin-right: 0;

                &:after {
                    transform: none;
                }
            }
        }

        .about-btn--primary {
            margin-right: 12px;
            border: 1px solid $secondaryColor;
            padding: 15px;
            margin-top: 20px;
            color: #fff;
            display: flex;
            font-size: large;
            background: $secondaryColor;
            justify-content: center;
            position: relative;
            transition: 300ms all;

            &:after {
                content: "";
                position: absolute;
                width: 100%;
                height: 100%;
                /* border-right: 1px solid #334c6f; */
                /* border-bottom: 1px solid #334c6f; */
                bottom: 0;
                transform: translate(12px, 12px);
                background: rgba(51, 76, 111, 0.5);
                z-index: -1;
                transition: 300ms transform;
            }

            &:hover {
                margin-right: 0;

                &:after {
                    transform: none;
                }
            }
        }

        .team-item {
            position: relative;
            overflow: hidden;

            img {
                width: 100%;
            }

            .content-part {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, 50%);
                text-align: center;
                z-index: 1;
                transition: $transition;
                opacity: 0;

                .name {
                    margin-bottom: 8px;
                    font-size: 22px;

                    a {
                        color: $whiteColor;

                        &:hover {
                            color: $titleColor;
                        }
                    }
                }

                .designation {
                    color: $whiteColor;
                    margin-bottom: 16px;
                    display: block;
                }

                .social-links {
                    li {
                        display: inline;
                        margin-right: 35px;

                        a {
                            display: inline-block;
                            color: $whiteColor;

                            &:hover {
                                color: $titleColor;
                            }
                        }

                        &:last-child {
                            margin: 0;
                        }
                    }
                }
            }

            &:after {
                position: absolute;
                content: '';
                left: 50%;
                top: 50%;
                width: calc(100% - 60px);
                height: calc(100% - 60px);
                transform: translate(-50%, 100%) scale(0.1);
                background: $secondaryColor;
                opacity: 0;
                border-radius: 3px;
                transition: $transition;
            }

            &:hover {
                .content-part {
                    transform: translate(-50%, -50%);
                    opacity: 1;

                    .name {
                        color: $whiteColor;
                    }
                }

                &:after {
                    transform: translate(-50%, -50%) scale(1);
                    opacity: 0.9;
                }
            }
        }

        .owl-item {
            &.center {
                .team-item {
                    .content-part {
                        transform: translate(-50%, -50%);
                        opacity: 1;
                    }

                    &:after {
                        transform: translate(-50%, -50%) scale(1);
                        opacity: 0.9;
                    }
                }
            }
        }

        .nav-style {
            position: relative;

            .owl-nav {
                display: block;
                position: absolute;
                top: -80px;
                right: 0;

                .owl-prev,
                .owl-next {
                    display: inline-block;

                    i {
                        transition: $transition;

                        &:before {
                            font-family: Flaticon;
                            font-size: 22px;
                        }
                    }

                    &:hover {
                        i {
                            color: $primaryColor;
                        }
                    }
                }

                .owl-prev {
                    i {
                        &:before {
                            content: "\f134";
                        }
                    }

                    &:after {
                        content: "/";
                        padding: 0 5px 0 5px;
                        position: relative;
                        top: -3px;
                    }
                }

                .owl-next {
                    i {
                        &:before {
                            content: "\f133";
                        }
                    }
                }
            }
        }

        &.orange-style {
            .team-item {
                &:after {
                    background: $orangeColor;
                }
            }
        }
    }

}

@media #{$mobile} {
    .rs-team.style1 .team-item .content-part .social-links li {
        margin-right: 24px;
    }
}