/*----------------------------------------*/
/*  01. Theme default CSS
/*----------------------------------------*/

/* 1. Theme default css */
@import url('https://fonts.googleapis.com/css?family=Rubik:300,400,500,600,700,800');
@import url('https://fonts.googleapis.com/css?family=Nunito:300,400,600,700,800,900');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400;1,500;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Brawler:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800;900&family=Playfair+Display:wght@400;500;600;700;800;900&display=swap');

@font-face {
    font-family: "GH Guardian Headline";
    src: url(./fonts/GHGuardianHeadline-Light.woff2?http3=true) format("woff2"), url(./fonts/GHGuardianHeadline-Light.woff?http3=true) format("woff"), url(./fonts/GHGuardianHeadline-Light.ttf?http3=true) format("truetype");
    font-weight: 300;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: "Guardian Egyptian Web";
    src: url(./fonts/GHGuardianHeadline-Light.woff2?http3=true) format("woff2"), url(./fonts/GHGuardianHeadline-Light.woff?http3=true) format("woff"), url(./fonts/GHGuardianHeadline-Light.ttf?http3=true) format("truetype");
    font-weight: 300;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: "GH Guardian Headline";
    src: url(./fonts/GHGuardianHeadline-LightItalic.woff2?http3=true) format("woff2"), url(./fonts/GHGuardianHeadline-LightItalic.woff?http3=true) format("woff"), url(./fonts/GHGuardianHeadline-LightItalic.ttf?http3=true) format("truetype");
    font-weight: 300;
    font-style: italic;
    font-display: swap
}

@font-face {
    font-family: "Guardian Egyptian Web";
    src: url(./fonts/GHGuardianHeadline-LightItalic.woff2?http3=true) format("woff2"), url(./fonts/GHGuardianHeadline-LightItalic.woff?http3=true) format("woff"), url(./fonts/GHGuardianHeadline-LightItalic.ttf?http3=true) format("truetype");
    font-weight: 300;
    font-style: italic;
    font-display: swap
}

@font-face {
    font-family: "GH Guardian Headline";
    src: url(./fonts/GHGuardianHeadline-Medium.woff2?http3=true) format("woff2"), url(./fonts/GHGuardianHeadline-Medium.woff?http3=true) format("woff"), url(./fonts/GHGuardianHeadline-Medium.ttf?http3=true) format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: "Guardian Egyptian Web";
    src: url(./fonts/GHGuardianHeadline-Medium.woff2?http3=true) format("woff2"), url(./fonts/GHGuardianHeadline-Medium.woff?http3=true) format("woff"), url(./fonts/GHGuardianHeadline-Medium.ttf?http3=true) format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: "GH Guardian Headline";
    src: url(./fonts/GHGuardianHeadline-MediumItalic.woff2?http3=true) format("woff2"), url(./fonts/GHGuardianHeadline-MediumItalic.woff?http3=true) format("woff"), url(./fonts/GHGuardianHeadline-MediumItalic.ttf?http3=true) format("truetype");
    font-weight: 500;
    font-style: italic;
    font-display: swap
}

@font-face {
    font-family: "Guardian Egyptian Web";
    src: url(./fonts/GHGuardianHeadline-MediumItalic.woff2?http3=true) format("woff2"), url(./fonts/GHGuardianHeadline-MediumItalic.woff?http3=true) format("woff"), url(./fonts/GHGuardianHeadline-MediumItalic.ttf?http3=true) format("truetype");
    font-weight: 500;
    font-style: italic;
    font-display: swap
}

@font-face {
    font-family: "GH Guardian Headline";
    src: url(./fonts/GHGuardianHeadline-Bold.woff2?http3=true) format("woff2"), url(./fonts/GHGuardianHeadline-Bold.woff?http3=true) format("woff"), url(./fonts/GHGuardianHeadline-Bold.ttf?http3=true) format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: "Guardian Egyptian Web";
    src: url(./fonts/GHGuardianHeadline-Bold.woff2?http3=true) format("woff2"), url(./fonts/GHGuardianHeadline-Bold.woff?http3=true) format("woff"), url(./fonts/GHGuardianHeadline-Bold.ttf?http3=true) format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: "GH Guardian Headline";
    src: url(./fonts/GHGuardianHeadline-BoldItalic.woff2?http3=true) format("woff2"), url(./fonts/GHGuardianHeadline-BoldItalic.woff?http3=true) format("woff"), url(./fonts/GHGuardianHeadline-BoldItalic.ttf?http3=true) format("truetype");
    font-weight: 700;
    font-style: italic;
    font-display: swap
}

@font-face {
    font-family: "Guardian Egyptian Web";
    src: url(./fonts/GHGuardianHeadline-BoldItalic.woff2?http3=true) format("woff2"), url(./fonts/GHGuardianHeadline-BoldItalic.woff?http3=true) format("woff"), url(./fonts/GHGuardianHeadline-BoldItalic.ttf?http3=true) format("truetype");
    font-weight: 700;
    font-style: italic;
    font-display: swap
}

@font-face {
    font-family: GuardianTextEgyptian;
    src: url(./fonts/GuardianTextEgyptian-Regular.woff2?http3=true) format("woff2"), url(./fonts/GuardianTextEgyptian-Regular.woff?http3=true) format("woff"), url(./fonts/GuardianTextEgyptian-Regular.ttf?http3=true) format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: "Guardian Text Egyptian Web";
    src: url(./fonts/GuardianTextEgyptian-Regular.woff2?http3=true) format("woff2"), url(./fonts/GuardianTextEgyptian-Regular.woff?http3=true) format("woff"), url(./fonts/GuardianTextEgyptian-Regular.ttf?http3=true) format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: GuardianTextEgyptian;
    src: url(./fonts/GuardianTextEgyptian-RegularItalic.woff2?http3=true) format("woff2"), url(./fonts/GuardianTextEgyptian-RegularItalic.woff?http3=true) format("woff"), url(./fonts/GuardianTextEgyptian-RegularItalic.ttf?http3=true) format("truetype");
    font-weight: 400;
    font-style: italic;
    font-display: swap
}

@font-face {
    font-family: "Guardian Text Egyptian Web";
    src: url(./fonts/GuardianTextEgyptian-RegularItalic.woff2?http3=true) format("woff2"), url(./fonts/GuardianTextEgyptian-RegularItalic.woff?http3=true) format("woff"), url(./fonts/GuardianTextEgyptian-RegularItalic.ttf?http3=true) format("truetype");
    font-weight: 400;
    font-style: italic;
    font-display: swap
}

@font-face {
    font-family: GuardianTextEgyptian;
    src: url(./fonts/GuardianTextEgyptian-Bold.woff2?http3=true) format("woff2"), url(./fonts/GuardianTextEgyptian-Bold.woff?http3=true) format("woff"), url(./fonts/GuardianTextEgyptian-Bold.ttf?http3=true) format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: "Guardian Text Egyptian Web";
    src: url(./fonts/GuardianTextEgyptian-Bold.woff2?http3=true) format("woff2"), url(./fonts/GuardianTextEgyptian-Bold.woff?http3=true) format("woff"), url(./fonts/GuardianTextEgyptian-Bold.ttf?http3=true) format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: GuardianTextEgyptian;
    src: url(./fonts/GuardianTextEgyptian-BoldItalic.woff2?http3=true) format("woff2"), url(./fonts/GuardianTextEgyptian-BoldItalic.woff?http3=true) format("woff"), url(./fonts/GuardianTextEgyptian-BoldItalic.ttf?http3=true) format("truetype");
    font-weight: 700;
    font-style: italic;
    font-display: swap
}

@font-face {
    font-family: "Guardian Text Egyptian Web";
    src: url(./fonts/GuardianTextEgyptian-BoldItalic.woff2?http3=true) format("woff2"), url(./fonts/GuardianTextEgyptian-BoldItalic.woff?http3=true) format("woff"), url(./fonts/GuardianTextEgyptian-BoldItalic.ttf?http3=true) format("truetype");
    font-weight: 700;
    font-style: italic;
    font-display: swap
}

@font-face {
    font-family: GuardianTextSans;
    src: url(./fonts/GuardianTextSans-Regular.woff2?http3=true) format("woff2"), url(./fonts/GuardianTextSans-Regular.woff?http3=true) format("woff"), url(./fonts/GuardianTextSans-Regular.ttf?http3=true) format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: "Guardian Text Sans Web";
    src: url(./fonts/GuardianTextSans-Regular.woff2?http3=true) format("woff2"), url(./fonts/GuardianTextSans-Regular.woff?http3=true) format("woff"), url(./fonts/GuardianTextSans-Regular.ttf?http3=true) format("truetype");
    font-weight: 400;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: GuardianTextSans;
    src: url(./fonts/GuardianTextSans-RegularItalic.woff2?http3=true) format("woff2"), url(./fonts/GuardianTextSans-RegularItalic.woff?http3=true) format("woff"), url(./fonts/GuardianTextSans-RegularItalic.ttf?http3=true) format("truetype");
    font-weight: 400;
    font-style: italic;
    font-display: swap
}

@font-face {
    font-family: "Guardian Text Sans Web";
    src: url(./fonts/GuardianTextSans-RegularItalic.woff2?http3=true) format("woff2"), url(./fonts/GuardianTextSans-RegularItalic.woff?http3=true) format("woff"), url(./fonts/GuardianTextSans-RegularItalic.ttf?http3=true) format("truetype");
    font-weight: 400;
    font-style: italic;
    font-display: swap
}

@font-face {
    font-family: GuardianTextSans;
    src: url(./fonts/GuardianTextSans-Bold.woff2?http3=true) format("woff2"), url(./fonts/GuardianTextSans-Bold.woff?http3=true) format("woff"), url(./fonts/GuardianTextSans-Bold.ttf?http3=true) format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: "Guardian Text Sans Web";
    src: url(./fonts/GuardianTextSans-Bold.woff2?http3=true) format("woff2"), url(./fonts/GuardianTextSans-Bold.woff?http3=true) format("woff"), url(./fonts/GuardianTextSans-Bold.ttf?http3=true) format("truetype");
    font-weight: 700;
    font-style: normal;
    font-display: swap
}

@font-face {
    font-family: GuardianTextSans;
    src: url(./fonts/GuardianTextSans-BoldItalic.woff2?http3=true) format("woff2"), url(./fonts/GuardianTextSans-BoldItalic.woff?http3=true) format("woff"), url(./fonts/GuardianTextSans-BoldItalic.ttf?http3=true) format("truetype");
    font-weight: 700;
    font-style: italic;
    font-display: swap
}

@font-face {
    font-family: "Guardian Text Sans Web";
    src: url(./fonts/GuardianTextSans-BoldItalic.woff2?http3=true) format("woff2"), url(./fonts/GuardianTextSans-BoldItalic.woff?http3=true) format("woff"), url(./fonts/GuardianTextSans-BoldItalic.ttf?http3=true) format("truetype");
    font-weight: 700;
    font-style: italic;
    font-display: swap
}


html,
body {
    font-size: 14px;
    color: $bodyColor;
    font-family: $bodyFont;
    vertical-align: baseline;
    font-weight: 400;
    // overflow-x: hidden;
}

body {
    overflow-x: hidden;
}

img {
    max-width: 100%;
    height: auto;
}

p {
    margin: 0 0 26px;
    line-height: 1.8;
    font-family: $paraFont;
}

button,
input,
a {

    &:active,
    &:focus {
        outline: none;
    }
}

a {
    color: $sidenavColor;
    transition: $transition;
    text-decoration: none !important;
    outline: none !important;
}

a:active,
a:hover {
    text-decoration: none;
    outline: 0 none;
    color: $orangeColor;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $titleFont;
    color: $titleColor;
    margin: 0 0 26px;
    line-height: 1.2;
}

h1 {
    font-size: 70px;
    font-weight: 700;
}

h2 {
    font-size: 36px;
    font-weight: 700;
}

h3 {
    font-size: 26px;
    font-weight: 700;
}

h4 {
    font-size: 24px;
    font-weight: 700;
}

h5 {
    font-size: 18px;
    font-weight: 700;
}

h6 {
    font-size: 16px;
    font-weight: 700;
}

.home-style2 {

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: $titleColor2;
    }
}

.uppercase {
    text-transform: uppercase !important;
}

.capitalize {
    text-transform: capitalize !important;
}


.bold-text {
    font-size: 20px;
    font-weight: bold;
    font-family: $titleFont;
}

.extra-bold {
    font-weight: 800 !important;
}

.z-index-1 {
    z-index: 1;
    position: relative;
}

.text-right {
    text-align: right;
}

ul {
    margin: 0px;
    padding: 0px;
}

/*--
    - Text color
-----------------------------------------*/
.white-color {
    color: $whiteColor !important;
}

.primary-color {
    color: $primaryColor !important;
}

.orange-color {
    color: $orangeColor !important;
}

.secondary-color {
    color: $secondaryColor !important;
}

.purple-color {
    color: $purpleColor !important;
}

.black-dark {
    background: #131313 !important;
}

.black-bg {
    background: #000000 !important;
}

/*--
    - Background color
-----------------------------------------*/
.gray-bg {
    background: $graybg;
}

.sec-bg {
    background: $secbg;
}

.white-bg {
    background: $whiteColor;
}

.primary-bg {
    background: $primaryColor;
}

.secondary-bg {
    background: $secondaryColor;
}

.event-bg {
    background: #f9f8f8;
}

.event2-bg {
    background: #F4F6F5;
}


/*--
    - Common Classes
-----------------------------------------*/
.fix {
    overflow: hidden
}

.clear {
    clear: both;
}

.f-left {
    float: left
}

.f-right {
    float: right
}

.no-border {
    border: none;
}

.no-shadow {
    box-shadow: none;
    text-shadow: none;
}

.no-padding {
    padding: 0;
}

.padding-0 {
    padding: 0;
}

.no-margin {
    margin: 0;
}

.margin-0 {
    margin: 0;
}

.no-gutter {
    margin-left: 0;
    margin-right: 0;

    >[class*="col-"] {
        padding-left: 0;
        padding-right: 0;
    }
}

.gutter-30 {
    margin-left: -15px;
    margin-right: -15px;

    >[class*="col-"] {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.gutter-35 {
    margin-left: -17.5px;
    margin-right: -17.5px;

    >[class*="col-"] {
        padding-left: 17.5px;
        padding-right: 17.5px;
    }
}

.container-fluid {
    padding-left: 60px;
    padding-right: 60px;
}

.uppercase {
    text-transform: uppercase;
}

.capitalize {
    text-transform: capitalize;
}

.relative {
    position: relative;
}

.zIndex {
    position: relative;
    z-index: 9;
}

.zIndex1 {
    position: relative;
    z-index: 1;
}

.y-middle {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.y-bottom {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -ms-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: flex-end;
    -webkit-align-items: flex-end;
    align-items: flex-end;
}

.rs-about {
    position: relative;
    overflow: hidden;
    @media (max-width: 1199px) {
       
    }

    &:before {
        content: "";
        position: absolute;
        width: 720px;
        height: 720px;
        border: 120px solid transparent;
        border-left-color: rgba(230, 8, 124, 0.08);
        border-bottom-color: rgba(230, 8, 124, 0.08);
        border-radius: 100%;
        top: -330px;
        right: -418px;
        z-index: -1;
    }

}

/* Slick Slider */
.slick-list {
    // margin: -15px;
}

/* item */
.slick-slide {
    // padding: 15px;
}

.slick-dots {
    text-align: center;
    bottom: -50px;

    li {
        width: unset;
        height: unset;
        line-height: 0;
        margin: 0 5px;

        button {
            width: 24px;
            height: 24px;
            display: inline-block;
            margin: 0 6px;
            padding: 3px 0;
            border-radius: 30px;
            background: $orangeColor;
            opacity: 0.75;
            color: #fff;
            font-size: 10px;

            &:before {
                content: none;
            }
        }

        &:hover,
        &.slick-active {
            button {
                background: $orangeColor;
                opacity: 1;
            }

        }
    }
}

.pagination {
    padding-left: 0 !important;
    margin-top: 30px;

    li {
        margin-top: 0 !important;
        margin-left: 0 !important;
    }
}

.menuImg {
    display: flex;
    flex-wrap: wrap;
    row-gap: 15px;

    >div {
        width: 25%;
        padding: 0 8px;
    }

    img {
        height: 65px;
        width: 100%;
        object-fit: cover;
    }

    p {
        font-family: $bodyFont;
        margin-bottom: 0;
        margin-top: 5px;
        font-size: 10px;
        line-height: 1;
        color: $titleColor;
    }
}

.inlineLinks {
    button {
        color: #434141;
        border: 0;
        background: none;
        padding: 0;
        padding-right: 5px;
        margin-right: 5px;
        line-height: 1;
        margin-bottom: 4px;
        text-align: left;

        &:not(:last-child) {
            border-right: 1px solid
        }

        &:hover {
            color: $orangeColor;
        }
    }
}

.additionalControllerExamination {
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
    padding-bottom: 10px;


    img {
        width: 150px;
    }

    .details {
        margin-left: 20px;

        p {
            margin-bottom: 0 !important;
            line-height: 1.5;

            &:nth-child(1) {
                font-family: $titleFont;
                font-size: 24px;
                font-weight: 700;
                line-height: 1;
                color: $orangeColor;
                margin-bottom: 5px !important;
            }

            &:nth-child(2) {
                color: #535356 !important;
                font-weight: 800;
                margin-bottom: 10px !important;
            }
        }
    }

    &:not(:last-of-type) {
        border-bottom: 1px solid #e2e2e2;
    }
}

.desc {
    font-family: $paraFont;
}

.about-content {
    p {
        text-align: justify;
    }
}

table {
    --bs-table-striped-bg: #D8E7FC !important;

    th,
    td {
        font-size: 12px;

        a {
            font-family: $paraFont;
        }

        p {
            margin-bottom: 0;
        }

        img {
            height: 60px;
        }
    }
}

// .pagination {
//     li {
//         .page-link {
//             background: #dcf1fd;
//             color: #267EF5;
//             font-size: 14px;

//             &:focus {
//                 box-shadow: none;
//             }
//         }

//         &.active {
//             .page-link {
//                 background: $secondaryColor;
//                 color: $whiteColor;
//             }
//         }
//     }
// }

.overflow-x {
    overflow-x: auto;
}

.status-open {
    color: #0c8b51;
}

.status-closed {
    color: red;
}

.widget {
    background: url(../img/box-pattern.png);
    background-repeat: no-repeat;
    background-position-x: right;

    h1 {
        display: flex;
        align-items: center;
        background: $orangeColor;
        color: $white;
        margin-bottom: 0;
        padding: 6px 12px;
        border-bottom: 1px solid #e2e2e2;
        font-size: 24px;

        span {
            margin-left: auto;
            color: $white;
            font-size: 18px;
        }
    }

    ul {
        list-style: none;
        padding: 0 12px;

        li {
            padding: 3px 0;
            line-height: 1.2;

            strong {
                color: $orangeColor;
            }

            a {
                color: $sidenavColor;
                font-size: 14px;

                &:hover {
                    color: $orangeColor;
                }
            }

            &:not(:first-child) {}

            >ul {
                // padding-left: 15px;
            }
        }


    }

    >div {
        border: 1px solid #e2e2e2;
        position: relative;
        overflow: hidden;

        // &:after {
        //     content: "";
        //     position: absolute;
        //     width: 400px;
        //     height: 400px;
        //     border: 100px solid transparent;
        //     border-bottom-color: rgba(42, 24, 129, 0.06);
        //     border-top-color: rgba(42, 24, 129, 0.06);
        //     border-right-color: rgba(42, 24, 129, 0.06);
        //     border-radius: 100%;
        //     bottom: -50px;
        //     left: -241px;
        //     z-index: -1;
        // }

        > .sideMenusWrap{
            >ul {
                margin-top: 15px;
            }
            @media (max-width: 1199px) {
                position: fixed;
                top: 70px;
                background: #fff;
                left: 0;
                bottom: 0;
                z-index: 10;
                max-width: 300px;
                transform: translateX(-100%);
                transition: 300ms transform;
                overflow: auto;
            }
            &.sideMenuOpen{
                transform: none;
            }
        }
    }
}

.heading {
    color: $orangeColor;
    margin-bottom: 10px;
    font-size: 24px;
}

.education {
    p {
        text-align: center !important;
        font-size: 12px;
        margin-bottom: 0 !important;
    }

    &:empty {
        display: none;
    }
}

.subTitle {
    margin-top: 5px;
    margin-bottom: 20px;

    p {
        margin-bottom: 10px !important;
        line-height: 1.1;

        &:nth-child(1) {
            font-weight: 800;
            color: #535356 !important;
            line-height: 1.1;
            font-size: 14px;
        }
    }

    &:empty {
        display: none;
    }
    &.text-center{
        p{
            text-align: center !important;
        }
    }
}

.bodyDiv{
    p:has(a){
        text-align: center !important;
    }
    ul{
        list-style-type: square;
    list-style-position: outside;
    padding-left: 16px;
    }
}

h3 {
    &.pName {
        margin-bottom: 0 !important;
    }
}

.about-intro {
    // position: sticky;
    // top: 220px;

    @media (max-width: 1199px) {
        // top: 164px;
    }
}

.downloadLinks {
    p {
        margin-bottom: 5px !important;
    }
}

.notficationTitle {
    font-size: 16px;
    color: $orangeColor;
    font-family: $titleFont;
}

.back {
    color: $secondaryColor;
    text-decoration: underline;
    cursor: pointer;
}

.grphs1{
   position: relative;
   background: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(246, 220, 135, 0.5) 100%);

    &:before{
        content: "";
        position: absolute;
        height: 100%;
        width: 100%;
        top: 0;
        background: url(../../assets/img/graphics1.svg);
        background-position: bottom;
        background-repeat: no-repeat;
        background-size: contain;
        z-index: -1;
    }
}

.faculty-content{
    p{
        margin-bottom: 12px;
        line-height: 1;
        &:last-child{
            margin-bottom: 0;
        }
    }
}

.rightBar {
    h4 {
        font-size: 20px;
        color: $orangeColor;
        margin-bottom: 0;
    }

    p {
        margin-bottom: 10px;
        text-align: justify;
    }

    .rBlock {
        &:not(:first-child) {
            margin-top: 10px;
            padding-top: 10px;
            border-top: 1px solid #535356;
        }
    }
}

.content-wrapper {
    h2 {
        color: $orangeColor;
        font-size: 24px;
        margin-bottom: 10px;
    }

    .content-section {
        // padding-top: 10px;

        &:not(:first-of-type) {
            padding-top: 20px;
        }

        h3 {
            color: $orangeColor;
            margin-bottom: 5px;
            font-size: 24px;
        }

        h4 {
            color: $navColor;
            margin-bottom: 8px;
            font-size: 18px;
        }

        ul {
            margin-bottom: 10px;
            padding-left: 20px;
        }

        ol {
            margin-bottom: 10px;
            padding-left: 0;
        }

        ul>li {
            margin-left: 18px;
            text-align: justify;

            &:not(:first-child) {
                margin-top: 15px;
            }
        }

        ol>li {
            margin-left: 18px;
            text-align: justify;

            &:not(:first-child) {
                margin-top: 15px;
            }
        }

        // ul {
        //     margin-bottom: 10px;
        //     padding-left: 20px;

        //     li {
        //         list-style: none;
        //         position: relative;
        //         padding-left: 18px;

        //         &:before {
        //             content: '';
        //             position: absolute;
        //             left: 0;
        //             top: 8px;
        //             width: 6px;
        //             height: 6px;
        //             border-radius: 50%;
        //             background: $sidenavColor;
        //         }

        //         &:not(:first-child) {
        //             margin-top: 2px;
        //         }
        //     }

        //     &.decimal-list {
        //         li {
        //             list-style: decimal;
        //             padding-left: 0;

        //             &:before {
        //                 display: none;
        //             }
        //         }
        //     }
        // }

        p {
            color: $navColor;
            margin-bottom: 15px;
            text-align: justify;
        }

        .events-short {
            .date-part {
                min-width: 85px;

                .date {
                    font-size: 14px;
                    font-family: 'EB Garamond', serif;
                    color: #112958;
                    font-weight: 700;
                }
            }

            p {
                margin-bottom: 5px;
                line-height: 1.2;
            }
        }
    }
}

.divider {
    height: 8px;
    background: #2A1881;
}

.moreContactInfo {
    background: #D8E7FC;
    padding: 15px;
    border-radius: 8px;
    display: block;
    cursor: pointer;

    >div {
        line-height: 15px;

        * {
            &:first-child {
                font-size: 30px;
            }

            &:last-child {
                font-size: 12px;
                background: #9ebde8;
                width: 20px;
                height: 20px;
                border-radius: 5px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: #d8e7fc;
            }
        }
    }
}

.accreditation {
    img {
        height: 120px;

        @media (max-width: 575px) {
            height: 90px;
        }
    }
}

.pageTopOffset {
    padding-top: 189px;

    @media #{$lg} {
        padding-top: 66px !important;
    }
}

.slick-next {
    right: 25px;
    z-index: 1;
}

.slick-prev {
    left: 25px;
    z-index: 1;
}

.slick-next:before,
.slick-prev:before {
    font-size: 30px;
    color: $secondaryColor;
}

.happenings-bg {
    background-image: url(../../assets/img/happenings/collage.png);
    background-color: #dcf1fd;
    background-position: left 0 bottom;
    background-size: 1200px auto;
    background-repeat: no-repeat;
    position: relative;

    &:before {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: rgba(220, 241, 253, 0.8);
    }

    .pull-down {
        margin-bottom: -85px;
    }
}

.lightBlue-bg {
    background: #f4e5d3;
}

.downloadBtns {
    a {
        padding: 10px;
        background: #dcf1fd;
        color: $secondaryColor;
        border: 0;
        font-size: 16px;
        border-radius: 3px;
        font-weight: 500;
        display: inline-block;

        &:not(:first-child) {
            margin-left: 25px;
        }

        i {
            margin-left: 10px;
        }
    }
}

.site-wrap {
    padding: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;

    img {
        margin-bottom: auto;
    }

    h4 {
        margin-bottom: 0;
        color: #101010;
        font-size: 14px;
    }
}

/*Video Icon*/
.popup-video,
.popup-videos {
    cursor: pointer;
}

.media-icon {
    position: relative;
    display: inline-block;
    z-index: 1;

    .popup-videos {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: $whiteColor;
        background: $primaryColor;
        width: 70px;
        height: 70px;
        border-radius: 100%;
        text-align: center;
        cursor: pointer;

        &:before {
            content: "";
            position: absolute;
            z-index: 0;
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
            display: block;
            width: 120px;
            height: 120px;
            border-radius: 50%;
            animation: pulse-border 1500ms ease-out infinite;
            transition: $transition;
            z-index: -1;
        }

        i {
            margin-left: 5px;

            &:before {
                font-size: 24px;
                line-height: 70px;
            }
        }

        &:hover {
            &:before {
                background: $primaryColor;
            }
        }
    }

    &.orange-color {
        .popup-videos {
            color: $orangeColor;
            background: $whiteColor;

            &:before {}

            i {
                &:before {
                    font-size: 30px;
                    line-height: 70px;
                }
            }

            &:hover {
                &:before {
                    background: $whiteColor;
                }
            }
        }
    }

    &.orange-color2 {
        .popup-videos {
            color: $whiteColor;
            background: $orangeColor;
            animation: circle-ripple 3s linear linear infinite;
            -webkit-animation: circle-ripple 3s linear infinite;

            &:before {
                display: none;
            }

            &:hover {
                &:before {
                    background: $orangeColor;
                }
            }
        }
    }

    &.yellow-color {
        .popup-videos {
            color: $secondaryColor;
            background: $yellowColor;

            &:before {}

            &:hover {
                &:before {
                    background: #1c335f;
                }
            }
        }
    }
}

.animate-border {
    background-image: linear-gradient(180deg, $secondaryColor 0%, $primaryColor 100%);
    width: 80px;
    height: 80px;
    line-height: 80px;
    text-align: center;
    display: inline-block;
    border-radius: 50%;
    position: relative;
    cursor: pointer;

    i {
        font-size: 20px;
        line-height: 80px;
        display: block;
        color: $whiteColor;
    }

    &:before {
        content: "";
        border: 2px solid $primaryColor;
        position: absolute;
        z-index: 0;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        display: block;
        width: 180px;
        height: 180px;
        border-radius: 50%;
        animation: zoomBig 3.25s linear infinite;
        -webkit-animation-delay: .75s;
        animation-delay: .75s;
        pointer-events: none;
    }

    &:after {
        content: "";
        border: 2px solid $primaryColor;
        position: absolute;
        z-index: 0;
        left: 50%;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        display: block;
        width: 180px;
        height: 180px;
        border-radius: 50%;
        animation: zoomBig 3.25s linear infinite;
        -webkit-animation-delay: 0s;
        animation-delay: 0s;
        pointer-events: none;
    }

    &.white-color {
        background: $whiteColor;

        i {
            color: $secondaryColor;
        }

        &:before {
            border: 2px solid $whiteColor;

        }

        &:after {
            border: 2px solid $whiteColor;
        }

        &.style3 {
            margin-bottom: 65px;
        }
    }
}

.readon {
    outline: none;
    padding: 12px 40px;
    border: 1px solid $primaryColor;
    border-radius: 30px;
    display: inline-block;
    text-transform: uppercase;
    font-size: 16px;
    font-family: $bodyFont;
    font-weight: 700;
    color: $titleColor;
    background: $grayColor;

    &.banner-style {
        color: $whiteColor;
        background: $primaryColor;
        padding: 16px 58px;

        &:hover {
            background: darken($primaryColor, 5%);
        }
    }

    &.purple-btn {
        color: $whiteColor;
        background: #625eaa;
        border: none;
        padding: 16px 40px;

        &:hover {
            background: $purpleColor;
        }
    }

    &.green-btn {
        color: #FFFFFF;
        background: #0C8B51;
        border-color: #02010100;
        border-radius: 30px;
        font-size: 14px;
        padding: 10px 30px 10px 30px;

        &:hover {
            opacity: 0.9;
            background: #0C8B51;
        }
    }

    &.green-banner {
        font-size: 16px;
        line-height: 38px;
        font-weight: 700;
        padding: 8px 30px;
        border-radius: 4px;
        background-color: rgb(12, 139, 81);
        border-color: transparent;
        color: rgb(255, 255, 255);

        &:hover {
            background: #08a355;
        }
    }

    &.white-color {
        color: $whiteColor !important;
        background: none !important;
        border: 1px solid $whiteColor;
        padding: 16px 40px;

        &:hover {
            background: $whiteColor !important;
            color: $purpleColor !important;
        }
    }

    &.orange-btn {
        color: $white;
        background: $secondaryColor;
        border: none;
        border-radius: 4px;
        outline: none;
        padding: 12px 35px;
        display: inline-block;
        font-size: 12px;
        font-weight: 600;

        &.main-home {
            padding: 10px 20px 10px 20px !important;

            &:hover {
                color: $whiteColor;
                background: $orangeColor;
            }
        }

        &:hover {
            color: $whiteColor;
            background: darken($orangeColor, 10%);
            border-color: darken($orangeColor, 10%);

        }

        &.transparent {
            &:hover {
                color: $titleColor5;
                background: $whiteColor;
                border-color: $whiteColor;
            }
        }
    }

    &.yellow-btn {
        background: #F4BF00;
        border-color: #F4BF00;
        border-radius: 4px 4px 4px 4px;

        &:hover {
            color: $whiteColor;
            background: #171F32;
            border-color: #171F32;
        }

        &.transparent3 {
            &:hover {
                color: $secondaryColor2;
                background: $whiteColor;
                border-color: $whiteColor;
            }
        }
    }

    &.transparent2 {
        background: $primaryColor;
        color: $titleColor;
        border: 1px solid $primaryColor;

        &:hover {
            color: $titleColor;
            background: transparent;
            border: 1px solid $primaryColor;
        }
    }

    &.register-btn {
        padding: 10px 50px;
        color: $whiteColor;
        background: $orangeColor;
        border-color: $orangeColor;
        font-weight: 500;
        width: 100%;
        border-radius: 5px;

        &:hover {
            background: darken($orangeColor, 5%);
        }
    }

    &.submit-btn {
        border: 2px solid;
        border-color: $orangeColor;
        padding: 10px 50px;
        line-height: normal;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 500;
        background: $orangeColor;
        color: $whiteColor;
        border-radius: 5px;
        margin-bottom: 12px;
        width: 100%;

        &:hover {
            background: transparent;
            color: $orangeColor;
        }
    }

    &.submit-requset {
        color: $whiteColor;
        background-color: #0FCB75;
        margin: 0px 0px 0px 0px;
        padding: 13px 35px 13px 35px;
        outline: none;
        border: none;
        padding: 12px 40px;
        border-radius: 3px;
        display: inline-block;
        text-transform: capitalize;
        font-size: 16px;
        font-family: 'Rubik', sans-serif;
        font-weight: 500;
        color: #ffffff;

        &:hover {
            opacity: 0.9;
            background-color: #0FCB75;
        }
    }

    &.border-less {
        border: none;

        &:hover {
            background: rgba(243, 248, 239, .8);
            color: $titleColor;
        }
    }

    &:hover {
        background: $primaryColor;
        color: $whiteColor;
    }
}

.readon-arrow {
    color: $titleColor;
    padding-right: 22px;
    position: relative;
    font-weight: 500;

    &:after {
        position: absolute;
        content: "\f136";
        font-family: Flaticon;
        font-style: normal;
        top: 50%;
        transform: translateY(-50%);
        right: 5px;
        color: $titleColor;
        transition: $transition;
        font-weight: 600;
        font-size: 14px;
    }

    &:hover {
        color: $primaryColor;

        &:after {
            right: 0;
            color: $primaryColor;
        }
    }
}


[class^="flaticon-"]:before,
[class*=" flaticon-"]:before,
[class^="flaticon-"]:after,
[class*=" flaticon-"]:after {
    font-family: Flaticon;
    font-size: 16px;
    font-style: normal;
    margin-left: 0;
}

.height-100 {
    height: 100%;
}

/*Pagination*/
.pagination-area {
    .pagination-part {
        display: inline-block;
        padding: 0 5px 0 10px;
        height: 60px;
        line-height: 60px;
        box-shadow: 0px 8px 26px 0px rgba(0, 0, 0, 0.1);

        li {
            position: relative;
            display: inline-block;
            padding: 0 20px 0 15px;
            text-align: center;
            cursor: pointer;

            a {
                display: inline-flex;
                align-items: center;
                color: $titleColor;

                &:hover {
                    color: $primaryColor;
                }
            }

            i {
                margin-left: 10px;
            }

            i:before {
                font-size: 28px;
                margin: 0;
                line-height: 60px;
            }

            &:before {
                content: '';
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                width: 5px;
                height: 5px;
                border-radius: 50%;
                background: rgb(214, 251, 255);
            }

            &:last-child {
                &:before {
                    display: none;
                }
            }

            &:hover,
            &.active {
                a {
                    color: $primaryColor;
                }
            }
        }
    }

    &.orange-color {
        .pagination-part {
            li {
                a {
                    &:hover {
                        color: $orangeColor;
                    }
                }

                &:before {
                    background: $orangeColor;
                }

                &:hover,
                &.active {
                    a {
                        color: $orangeColor;
                    }
                }
            }
        }
    }
}

.dots {
    list-style: disc;
    margin: 0 0 1.5em 3em;
}

.gridFilter {
    .react-tabs__tab {
        display: inline-block;

        button {
            background: transparent;
            border: 0;
            font-size: 15px;
            font-weight: 700;
            margin-left: 20px;
            outline: none;
            color: $bodyColor;
            cursor: pointer;
        }

        &:hover,
        &.react-tabs__tab--selected {
            button {
                color: $orangeColor;
            }

        }

        &:first-child {
            button {
                margin-left: 0;
            }
        }
    }

    &.style2 {
        .react-tabs__tab {
            button {
                background: #f9f7f8;
                border: none;
                border-radius: 3px;
                font-weight: 500;
                font-size: 16px;
                color: #101010;
                padding: 10px 30px;
                outline: none;
                margin: 0 10px 20px;
            }

            &:hover,
            &.react-tabs__tab--selected {
                button {
                    background: #fff;
                    color: $orangeColor;
                    box-shadow: 0 0 30px #eee;
                }
            }
        }

        &.modify1 {
            .react-tabs__tab {
                button {
                    margin: 0 0px 20px;
                }
            }
        }
    }
}

blockquote {
    margin: 35px 0;
    padding: 40px;
    color: #666;
    position: relative;
    background: #fff;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 55px;
    font-weight: 400;
    font-style: italic;
    text-align: left;
    clear: both;
    box-shadow: 0 0 150px #eee;

    &:before {
        position: absolute;
        content: "";
        display: block;
        left: 0;
        top: 0;
        height: 100%;
        width: 10px;
        background: $primaryColor;
    }

    p {
        font-size: 16px;
        margin: 0;
    }
}

.title-color {
    color: $titleColor;
}

.body-color {
    color: $bodyColor;
}

.media-icon {
    position: relative;
    display: inline-block;
    z-index: 1;
}

// Section Title
.sec-title {
    .sub-title {
        font-size: 30px;
        line-height: 28px;
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 8px;
        font-family: $titleFont;

        &.primary {
            color: $primaryColor;
        }

        &.secondary {
            color: $secondaryColor;
        }

        &.white {
            color: $whiteColor;
        }

        &.dark {
            color: $titleColor;
        }

        &.orange {
            color: $orangeColor;
        }
    }

    .desc {
        &.big {
            font-size: 18px;
            line-height: 30px;
        }
    }

    .midline {
        font-size: 36px;
        color: $titleColor;
    }
}



.left-top-shape {
    position: absolute;
    left: -55px;
    top: 65px;
}

.right-top-shape {
    position: absolute;
    right: -115px;
    top: 25px;
}

// others common css here :)
.check-square {
    li {
        position: relative;
        padding-left: 25px;
        line-height: 35px;
        border: none;
        font-size: 15px;
        font-weight: 500;
        color: #454545;

        &:before {
            position: absolute;
            left: 0;
            z-index: 0;
            content: "\f058";
            font-weight: 400;
            font-family: 'Font Awesome 5 Pro';
            color: rgb(120, 124, 242);
        }
    }
}

.shape-part {

    .right-side,
    .left-side {
        position: absolute;
        bottom: 0px;
        left: 0;
    }

    .right-side {
        left: auto;
        right: 0;
    }
}



.owl-nav {
    >div {
        span {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 70px;
            height: 70px;
            display: inline-block;
            background: #f6f2ed;
            line-height: 65px;
            text-align: center;
            border-radius: 50%;

            >img {
                min-width: 10px;
                max-width: 25px;
            }
        }
    }

    .owl-next {
        >span {
            right: -35px;
        }
    }

    .owl-prev {
        >span {
            left: -35px;
        }
    }
}




select {
    cursor: pointer;
}

.form-group {
    margin-bottom: 1rem;
}

.watermark-animate {
    -webkit-animation: watermark-animate 8s infinite;
    animation: watermark-animate 8s infinite;
    animation-direction: alternate-reverse;
    -webkit-animation-timing-function: linear;
    animation-timing-function: linear;
}

@keyframes watermark-animate {
    0% {
        left: 0;
    }

    100% {
        left: 100%;
    }
}

@keyframes circle-ripple {
    0% {
        box-shadow: 0 0 0 0 rgba(255, 84, 33, 0.3), 0 0 0 1em rgba(255, 84, 33, 0.3), 0 0 0 3em rgba(255, 84, 33, 0.3), 0 0 0 5em rgba(255, 84, 33, 0.3);
    }

    100% {
        box-shadow: 0 0 0 1em rgba(255, 84, 33, 0.3), 0 0 0 3em rgba(255, 84, 33, 0.3), 0 0 0 5em rgba(255, 84, 33, 0.3), 0 0 0 8em rgba(255, 84, 33, 0);
    }
}

@-webkit-keyframes circle-ripple {
    0% {
        box-shadow: 0 0 0 0 rgba(255, 84, 33, 0.3), 0 0 0 1em rgba(255, 84, 33, 0.3), 0 0 0 3em rgba(255, 84, 33, 0.3), 0 0 0 5em rgba(255, 84, 33, 0.3);
    }

    100% {
        box-shadow: 0 0 0 1em rgba(255, 84, 33, 0.3), 0 0 0 3em rgba(255, 84, 33, 0.3), 0 0 0 5em rgba(255, 84, 33, 0.3), 0 0 0 8em rgba(255, 84, 33, 0);
    }
}



/*--
    -Removeable
-----------------------------------------*/
.grey-bg-2 {
    background: $grey-2;
}

.black-bg {
    background: $black;
}

.theme-bg {
    background: $theme-color;
}

.theme-bg {
    background: $theme-color;
}

.footer-bg {
    background: $footer-bg;
}

.black-color {
    color: $black;
}

.theme-color {
    color: $theme-color;
}

.theme_button {
    color: #ffffff;
    background: #e6087c;
    border-color: #e6087c;
    border-radius: 3px;
    font-weight: 500;
    padding: 5px 10px;
}

.theme_button:hover {
    color: #fff;
}

.notification-center{
    background: #334c6f;
    svg{
        top: 0;
        bottom: 0;
        opacity: 0.6;
    }
}

.dirDetail {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
        height: 350px;
        margin-bottom: 8px;
        width: 280px;
        object-fit: cover;
    }

    h3 {
        font-family: $titleFont;
        margin-bottom: 10px;
        color: $purpleColor;
    }

    h4 {
        margin-bottom: 10px;
        font-size: 16px;
    }
}

.slideNav {

    // padding: 5px;
    img {
        height: 80px;
        width: 100%;
        object-fit: cover;
        border-radius: 8px;
    }

    p {
        margin-bottom: 0;
        text-align: center;
        line-height: 1.2;
        font-size: 12px;
        margin-top: 5px;
        font-weight: 600;
    }
}

.navigationSlider {
    .slick-track {
        padding: 40px 0 12px;
    }

    .slick-slide {
        padding: 5px;
        cursor: pointer;
        border-radius: 8px;
        transform: scale(0.9);
        transition: 300ms transform;

        &.slick-current {
            transform: scale(1);
            background: rgba(156, 6, 104, 0.2);

            .slideNav {
                p {
                    color: $purpleColor;
                }
            }
        }
    }
}

/* Custom Container Width :1440px. */
@media (min-width: 1440px) {
    .container {
        max-width: 1270px;
        width: 100%;
    }
}

/* Laptop Device :1600px. */
@media #{$laptop} {

    .sec-left,
    .sec-right {
        display: none;
    }
}

/* XL Device :1366px. */
@media #{$xl} {
    .container {
        max-width: 1170px;
        width: 100%;
    }

    .container-fluid2 {
        padding-left: 0px;
        padding-right: 0px;
    }
}

/* Lg Device :1199px. */
@media #{$lg} {
    .pl-100 {
        padding-left: 35px;
    }

    .pr-180 {
        padding-right: 0px;
    }

    .bg13 {
        padding: 81px 60px 81px 60px;
    }

    .container-fluid2 {
        padding-left: 0px;
        padding-right: 0px;
    }

    .container {
        max-width: 960px;
    }

    .sec-title .title {
        font-size: 35px;
    }

    .sec-title3 .title.title2 {
        font-size: 34px;
        line-height: 45px;
    }

    .sec-title .title {
        font-size: 27px;
    }
}

/* Custom Container Width :992px. */
@media (min-width: 992px) {
    .pr-95 {
        padding-right: 0;
    }

    .lg-pr-0 {
        padding-right: 0;
    }

    .lg-pl-0 {
        padding-left: 0;
    }
}

@media #{$only_lg} {
    .sec-title4 .title {
        font-size: 28px;
        line-height: 1.2;
    }
}

@media #{$md} {
    .order-last {
        -ms-flex-order: unset !important;
        order: unset !important;
    }

    .hidden-md {
        display: none;
    }

    .md-text-left {
        text-align: left !important;
    }

    .pt-411 {
        padding-top: 372px;
    }

    .bg13 {
        padding: 100px 50px 100px 50px;
    }

    .bg8 {
        padding: 25px 0 25px;
    }

    .md-pl-pr-15,
    .md-col-padding [class*="col-"] {
        padding-right: 15px !important;
        padding-left: 15px !important;
    }

    .container {
        max-width: 720px;
    }

    .container-fluid {
        padding-left: 15px;
        padding-right: 15px;
    }

    .md-text-left {
        text-align: left;
    }

    .sec-title6 .sub-title {
        font-size: 17px;
    }

    .sec-title3 h2,
    .sec-title3 .title {
        font-size: 32px;
        line-height: 43px;
    }

    .sec-title3 .desc {
        font-size: 15px;
    }

    .sec-title2 .sub-title {
        font-size: 16px;
        line-height: 23px;
    }

    .sec-title2 .title {
        font-size: 34px;
        line-height: 44px;
    }

    .sec-title .title {
        font-size: 30px;
    }
}


@media #{$sm} {
    .container {
        max-width: 540px;
    }

    .hidden-sm {
        display: none;
    }

    .sm-text-center {
        text-align: center !important;
    }

    .sm-text-left {
        text-align: left !important;
    }

    .sec-title .title {
        font-size: 30px;
    }

    .sec-title .sub-title {
        font-size: 16px;
    }

    .sec-title2 .title {
        font-size: 30px;
        line-height: 1.4;
    }

    .sec-title3 .title.title3 {
        font-size: 36px;
        line-height: 46px;
    }

    .pr-183 {
        padding-right: 0;
    }

    .bg13 {
        padding: 70px 40px 70px 40px;
    }
}

@media #{$xs} {
    .readon {
        padding: 10px 30px !important;
        font-size: 15px;
    }

    .bg8 {
        background: none;
        padding: 50px 0 50px;
    }

    .readon.green-btn {
        font-size: 12px;
        padding: 8px 23px !important;
    }

    .readon.banner-style {
        padding: 12px 40px;
        font-size: 15px;
    }

    .rs-banner.style5 {
        min-height: 400px;
    }

    .readon2.banner-style {
        padding: 12px 30px;
    }
}

.modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
}

.modal-dialog {
    max-width: 800px;
    margin: 1.75rem auto;
    padding: 210px 20px 0;
    @media (max-width: 1199px) {
        padding: 70px 20px 0;
    }
}

@media #{$mobile} {
    .container-fluid {
        padding: 0 15px;
    }

    .sec-title3 .title.title3 {
        font-size: 29px;
        line-height: 39px;
    }

    .sec-title5 .description br {
        display: none;
    }

    .sec-title3 .title.title2 {
        font-size: 24px;
        line-height: 40px;
    }

    .sec-title6 .title {
        font-size: 30px;
        line-height: 40px;
    }

    .sec-title4 .title {
        font-size: 22px;
        line-height: 32px;
    }

    .sec-title4 .title {
        font-size: 26px;
        line-height: 36px;
    }

    .sec-title4 .sub-title {
        font-size: 15px;
        line-height: 26px;
    }

    .sec-title3 .sub-title {
        font-size: 14px;
        line-height: 26px;
    }

    .sec-title3 h2,
    .sec-title3 .title {
        font-size: 26px;
        line-height: 36px;
    }

    .sec-title2 .title {
        font-size: 25px;
    }

    .sec-title .title {
        font-size: 25px;
    }

    blockquote {
        padding: 20px;
    }

    .readon.orange-btn {
        font-size: 14px;
    }

    .readon.banner-style {
        font-size: 14px;
        font-weight: 500;
    }
}

.row-cols-7>* {
    flex: 0 0 auto;
    width: 14.285714%;
}

@media (min-width: 576px) {
    .row-cols-sm-7>* {
        flex: 0 0 auto;
        width: 14.285714%;
    }
}

@media (min-width: 768px) {
    .row-cols-md-7>* {
        flex: 0 0 auto;
        width: 14.285714%;
    }
}

@media (min-width: 992px) {
    .row-cols-lg-7>* {
        flex: 0 0 auto;
        width: 14.285714%;
    }
}

@media (min-width: 1200px) {
    .row-cols-xl-7>* {
        flex: 0 0 auto;
        width: 14.285714%;
    }
}

@media (min-width: 1400px) {
    .row-cols-xxl-7>* {
        flex: 0 0 auto;
        width: 14.285714%;
    }
}

// departments css
.department-menu {
    background: $secondaryColor;

    @media (max-width: 1199px) {
        position: relative;
    }

    div {
        >ul {
            list-style: none;
            display: flex;

            @media (max-width: 1199px) {
                flex-direction: column;
                position: absolute;
                background: $secondaryColor;
                z-index: 1;
                width: 100%;
                left: 0;
                padding: 0 15px;
                max-height: 400px;
                overflow: auto;
            }

            >li {
                position: relative;

                a {
                    display: block;
                    color: $whiteColor;
                    padding-top: 10px;
                    padding-bottom: 10px;
                }

                &:not(:first-child) {
                    >a {
                        margin-left: 30px;

                        @media (max-width: 991px) {
                            margin-left: 0;
                        }
                    }
                }

                >ul {
                    list-style: none;
                    position: absolute;
                    z-index: 1;
                    background: $secondaryColor;
                    min-width: 200px;
                    display: none;

                    @media (max-width: 1199px) {
                        position: relative;
                    }

                    li {
                        >a {
                            padding-left: 30px;
                            padding-right: 30px;
                        }
                    }
                }

                &.hasSubmenu {
                    &:hover {
                        >ul {
                            display: block;
                        }
                    }

                    >a {
                        padding-right: 15px;
                        position: relative;

                        &:after {
                            content: "\f078";
                            position: absolute;
                            left: auto;
                            right: 0;
                            top: 50%;
                            bottom: unset;
                            height: auto;
                            width: auto;
                            background: unset;
                            text-align: center;
                            z-index: 9999;
                            font-size: 10px;
                            display: block;
                            cursor: pointer;
                            transform: translateY(-50%);
                            font-weight: 100;
                            font-family: 'Font Awesome 5 Pro';
                        }

                        &:hover {
                            &:after {
                                content: "\f077";
                            }
                        }
                    }

                    li {
                        &.hasSubmenu {
                            position: relative;

                            >a {
                                padding-right: 30px;
                                position: relative;

                                &:after {
                                    content: "\f054";
                                    position: absolute;
                                    left: auto;
                                    right: 10px;
                                    top: 50%;
                                    bottom: unset;
                                    height: auto;
                                    width: auto;
                                    background: unset;
                                    text-align: center;
                                    z-index: 9999;
                                    font-size: 10px;
                                    display: block;
                                    cursor: pointer;
                                    transform: translateY(-50%);
                                    font-weight: 100;
                                    font-family: 'Font Awesome 5 Pro';
                                }


                            }

                            >ul {
                                list-style: none;
                                display: none;
                                right: 0;
                                transform: translateX(100%);
                                top: 0;
                                z-index: 1;
                                background: $secondaryColor;
                                min-width: 200px;
                                position: absolute;
                            }

                            &:hover {
                                >ul {
                                    display: block;
                                }
                            }
                        }
                    }
                }

                &:last-child{
                    &.ms-lg-auto{
                        > ul{
                            right: 0
                        }
                    }
                }
            }
        }
    }

}