.rs-contact {
    &.style2 {
        .map-canvas {
            iframe {
                height: 590px;
                filter: grayscale(100%);
            }
        }
        .contact-box{
            .box-wraper{
                background-color: #f9f8f8;
                padding: 40px 20px 60px 55px;
                border-radius: 5px;
                .box-title {
                    text-align: center;
                    font-size: 28px;
                    line-height: 35px;
                    font-weight: 700;
                    margin-bottom: 35px;
                    color: $titleColor;
                }
                .address-box {
                    display: flex;
                    .address-icon{
                        min-width: 60px;
                        margin-right: 20px;
                        padding-top: 4px;
                        i{
                            color: $orangeColor;
                            font-size: 35px;
                        }           
                    }
                    .address-text{
                        .label{
                            font-size: 15px;
                            font-weight: 700;
                            display: block;
                            color: #333333;
                            font-family: $titleFont;
                            margin-bottom: 4px;
                        }
                        .phone,
                        .email,
                        .address {
                            color: #111111;
                            font-size: 18px;
                            line-height: 31px;
                            font-family: $titleFont;
                            font-weight: 700;
                        }
                        a {
                            &:hover {
                                color: $orangeColor;
                            }
                        }
                    }
                }
            }
        }
        .contact-widget{
            padding: 70px 100px 70px 100px;
            .from-control{
                padding: 10px 18px;
                border-radius: 3px;
                border: 1px solid #F1F1F1;
                color: #767676;
                background-color:  #f9f8f8;
                width: 100%;
                max-width: 100%;
                outline: none;
                &:active,
                &:focus {
                    outline: none;
                }
            }
            textarea {
                height: 140px;
                outline: none;
                &:active,
                &:focus {
                    outline: none;
                }
            }
            .btn-send {
                box-shadow: 0px 28px 50px 0px rgba(0, 0, 0, 0.05);
                outline: none;
                border: none;
                padding: 12px 40px;
                border-radius: 3px;
                display: inline-block;
                font-size: 16px;
                font-weight: 500;
                color: $whiteColor;
                background: $orangeColor;
                transition: all 0.3s ease;
                position: relative;
                overflow: hidden;
                width: 100%;
                text-transform: uppercase;
                &:hover {
                    opacity: 0.90;
                }
            }
            ::-webkit-input-placeholder { /* Chrome/Opera/Safari */
                color: #767676;
                opacity: 1;
            }
            ::-moz-placeholder { /* Firefox 19+ */
                color: #767676;
                opacity: 1;
            }
            :-ms-input-placeholder { /* IE 10+ */
                color: #767676;
                opacity: 1;
            }
            :-moz-placeholder { /* Firefox 18- */
                color: #767676;
                opacity: 1;
            }
        }
    }
}

@media only screen and (max-width: 1399px) {
    .rs-contact {
        &.style2 {
            .contact-box {
                .box-wraper {
                    padding: 40px 20px 60px 30px;
                    .address-box {
                        .address-icon {
                            min-width: 40px;
                            margin-right: 15px;
                        }
                    }
                }
            }
        }
    }
}

@media #{$lg} {
    .gutter-35 {
        margin-left: -15px;
        margin-right: -15px;
        > [class*="col-"] {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
    .rs-contact {
        &.style2 {
            .contact-box {
                .box-wraper {
                    padding: 40px 20px 50px 30px;
                    .address-box {
                        display: block;
                        text-align: center;
                        .address-icon {
                            margin-right: 0;
                            margin-bottom: 5px;
                        }
                        .address-text {
                            .label {
                                margin-bottom: 0;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media #{$lg_to_sm} {
    
}

@media #{$md} {
    .rs-contact {
        &.style2 {
            .sec-title {
                .desc {
                    &.big {
                        br {
                            display: none;
                        }
                    }
                }
            }
            .map-canvas {
                iframe {
                    height: 450px;
                }
            }
            .contact-widget {
                padding: 70px 80px;
            }
        }
    }
    .rs-contact.style2 .contact-box .box-wraper {
       padding-left: 20px;
    }
}

@media #{$sm} {
    .rs-contact{
        &.style2 {
            .sec-title {
                .desc {
                    &.big {
                        font-size: 15px;
                        line-height: 25px;
                    }
                }
            }
            .contact-widget {
                padding: 58px 50px 70px;
            }
        }
    }
}

@media #{$xs} {
    .rs-contact {
        &.style2 {
            .map-canvas {
                iframe {
                    height: 400px;
                }
            }
            .contact-widget {
                padding: 58px 30px 70px;
            }
        }
    }
}

@media #{$mobile} {
    .rs-contact {
        &.style2 {
            .sec-title {
                margin-bottom: 30px;
                .desc {
                    &.big {
                        display: none;
                    }
                }
            }
            .map-canvas {
                iframe {
                    height: 400px;
                }
            }
            .contact-widget {
                padding: 45px 15px 60px;
            }
        }
    }
}
