/*Intro Section*/
.intro-section {
    position: relative;

    /*Intro Tabs Style*/
    .intro-tabs {
        position: relative;
        border: none;
        white-space: nowrap;
        overflow: auto;

        .react-tabs__tab {
            position: relative;
            text-align: center;
            // width: 20%;
            min-width: 90px;
            display: inline-block;

            button {
                position: relative;
                display: block;
                width: 100%;
                font-size: 12px;
                border-radius: 3px;
                background: rgba(255, 84, 33, 0.2);
                color: $titleColor5;
                border: none;
                font-weight: 500;
                padding: 10px;
                text-transform: capitalize;
                transition: all 500ms ease;
            }

            &:not(:first-child) {
                margin-left: 10px;
            }

            &:hover,
            &.react-tabs__tab--selected {
                button {
                    color: $whiteColor;
                    background: $orangeColor;
                    border-color: $orangeColor;
                }
            }
        }

        .react-tabs__tab-panel {
            position: relative;
            overflow: hidden;
            background-color: $whiteColor;

            .minutes {
                position: relative;
                color: #21a7d0;
                font-size: 14px;
                font-weight: 500;
                margin-top: 12px;
            }
        }
    }
}

/* Course Overview */
.course-overview {
    position: relative;
    // padding: 30px;
    // background: #fcfcfc;
    // margin-top: 30px;

    .instructor-title {
        position: relative;
        color: $titleColor;
        font-size: 20px;
        font-weight: 600;
        line-height: 1.3em;
        margin-bottom: 18px;
    }

    .inner-box {
        position: relative;

        img {
            height: 350px;
            width: 300px;
            object-fit: cover;
        }

        // padding:0px 40px 40px;
        h3 {
            position: relative;
            color: $orangeColor;
            margin-bottom: 10px;
            font-size: 24px;
            font-weight: 700;

            &:empty {
                display: none;
            }
        }

        .exInfo {
            color: $titleColor;
            font-weight: 700;
        }

        h4 {
            position: relative;
            color: $orangeColor;
            font-weight: 600;
            line-height: 1.3em;
            margin-bottom: 5px;
        }

        h5 {
            font-size: 18px;
            color: $orangeColor;
            margin-bottom: 5px;
        }

        p {
            position: relative;
            margin-bottom: 15px;
            text-align: justify;

            span {
                font-weight: 700;
            }

            &:empty {
                display: none;
            }
        }

        .student-list {
            position: relative;
            margin-top: 25px;
            margin-bottom: 30px;

            li {
                position: relative;
                color: #393939;
                font-size: 16px;
                font-weight: 500;
                line-height: 1.3em;
                margin-right: 80px;
                display: inline-block;
                list-style: disc;
                margin-bottom: 10px;

                .fa {
                    color: $orangeColor;
                    margin: 0px 2px;
                }

                &:last-child {
                    margin-right: 0px;
                }
            }
        }

        .review-list {
            position: relative;
            margin-bottom: 20px;

            li {
                position: relative;
                color: #626262;
                font-size: 14px;
                margin-bottom: 14px;
                padding-left: 30px;

                &:before {
                    position: absolute;
                    content: "\f101";
                    left: 0px;
                    top: 5px;
                    color: $orangeColor;
                    font-size: 14px;
                    line-height: 1em;
                    font-family: 'Font Awesome 5 Pro';
                }

                &:last-child {
                    margin-bottom: 0px;
                }
            }
        }
    }
}

/*Course Review*/
.cource-review-box {
    position: relative;
    padding: 0px 40px;

    h4 {
        position: relative;
        color: $titleColor;
        font-weight: 700;
        line-height: 1.3em;
        margin-bottom: 15px;
    }

    .rating {
        position: relative;
        color: #626262;
        font-size: 16px;

        .fa {
            position: relative;
            color: $orangeColor;
            margin: 0px 2px;
            font-size: 16px;
        }
    }

    .total-rating {
        position: relative;
        color: $orangeColor;
        font-size: 16px;
        font-weight: 600;
        margin-right: 5px;
    }

    .text {
        position: relative;
        color: #626262;
        font-size: 16px;
        line-height: 1.8em;
        margin-top: 15px;
    }

    .helpful {
        position: relative;
        color: #393939;
        font-size: 16px;
        line-height: 1.8em;
        margin-top: 12px;
        font-weight: 500;
        margin-bottom: 15px;
    }

    .like-option {
        position: relative;

        li {
            position: relative;
            margin-right: 10px;
            display: inline-block;

            i {
                position: relative;
                width: 36px;
                height: 36px;
                color: #626262;
                text-align: center;
                line-height: 36px;
                border-radius: 50px;
                display: inline-block;
                background-color: #eaeff5;
                cursor: pointer;

                &:hover {
                    color: #21a7d0;
                }
            }
        }

        .report {
            position: relative;
            color: #333;
            font-size: 14px;
            font-weight: 500;

            &:hover {
                color: #21a7d0;
            }
        }
    }

    .more {
        position: relative;
        color: $orangeColor;
        font-size: 14px;
        font-weight: 500;
        margin-top: 30px;
        display: inline-block;
    }
}

@media #{$lg} {
    .intro-section .intro-tabs .react-tabs__tab button {
        padding: 12px 10px 10px;
    }

    .btn {
        padding: 14px 20px;
    }
}

@media #{$md} {
    .course-overview .inner-box {
        // padding: 0px 20px 40px;
    }
}

@media #{$mobile} {
    .intro-section .intro-tabs {
        white-space: nowrap;
        overflow: auto;
    }

    .intro-section .intro-tabs .react-tabs__tab {}
}