.pagination {
    display: flex;
    list-style-type: none;
    padding: 0;
    justify-content: center;
    margin-top: 20px;
  }
  
  .page-item {
    margin: 0 5px;
  }
  
  .page-link {
    padding: 5px 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
    cursor: pointer;
  }
  
  .page-link:hover {
    background-color: #f0f0f0;
  }
  
  .active .page-link {
    background-color: #007bff;
    color: #fff;
    border-color: #007bff;
  }
  
  .active .page-link:hover {
    background-color: #0056b3;
  }
  