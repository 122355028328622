.rs-about {
    &.style1 {
        .about-part {
            background: #e7f4f6;
            padding: 60px 60px 70px;
            border-radius: 5px;
            margin-top: -60px;

            .desc {
                font-size: 18px;
                line-height: 30px;
                color: $bodyColor;
            }

            .sign-part {
                display: flex;
                align-items: center;

                .img-part {
                    padding-right: 30px;
                }

                .author-part {
                    span {
                        display: block;
                    }

                    .post {
                        font-size: 18px;
                    }
                }
            }
        }

        .notice-bord {
            &.style1 {
                margin-top: -60px;

                .title {
                    background: $primaryColor;
                    font-size: 20px;
                    text-transform: uppercase;
                    padding: 18px 25px;
                    text-align: center;
                    font-weight: 600;
                    color: $whiteColor;
                    margin-bottom: 10px;
                }

                li {
                    position: relative;
                    background: #e7f4f6;
                    margin-bottom: 12px;
                    border-radius: 3px;
                    padding: 20px;
                    padding-left: 0 !important;
                    overflow: hidden;

                    .date {
                        position: absolute;
                        left: 20px;
                        top: 50%;
                        transform: translateY(-50%);
                        text-align: center;
                        color: $primaryColor;
                        border-right: 1px solid $primaryColor;
                        padding-right: 10px;
                        font-weight: 600;
                        width: 60px;

                        span {
                            font-size: 22px;
                            font-family: $titleFont;
                            display: block;
                        }
                    }

                    .desc {
                        padding-left: 95px;
                        font-weight: 500;
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }

        .img-part {
            // position: sticky;
            // top: 220px;

            @media #{$lg} {
                // top: 100px;
            }

            @media #{$md} {
                // top: 100px;
            }

            .shape {
                position: absolute;
            }

            .top-center {
                top: -30px;
                left: 49%;
                z-index: -1;
            }

            .left-bottom {
                left: 0;
                bottom: 0;

                &.second {
                    bottom: -40px;
                }
            }
        }

        .info-part {

            h2 {
                color: $purpleColor;
                margin-bottom: 10px;
                font-size: 30px;

                @media (max-width: 575px) {
                    font-size: 22px;
                }
            }

            h4 {
                color: $purpleColor;
                margin-bottom: 10px;
                font-size: 20px;

                @media (max-width: 575px) {
                    font-size:18px;
                }
            }

            .exInfo {
                &.white {
                    color: #fff;
                }

                p {
                    margin-bottom: 0;
                }
            }
        }
    }
}





/* Laptop :1600px. */
@media #{$laptop} {}

/* Custom Container Width :1400px. */
@media (max-width: 1400px) {
    .rs-about.style4 .about-content .contact-part li {
        margin-right: 15px;
    }

    .rs-about.style4 .about-content .contact-part li .img-part {
        margin-right: 15px;
    }

}

/* XL Device :1366px. */
@media #{$xl} {
    .rs-about.style2 .sec-title .title {
        font-size: 34px;
    }

}

/* Lg Device :1199px. */
@media #{$lg} {
    .rs-about.style10 .shape-icons .shape.two {
        top: 14%;
        left: 13%;
    }

    .rs-about.style7 .sec-title2 .title {
        font-size: 30px;
        line-height: 40px;
    }

    .rs-about.style4 .about-content .contact-part li {
        margin-right: 10px;
    }

    .rs-about.style4 .about-content .contact-part li .desc a {
        font-size: 18px;
        margin-top: 5px;
    }

    .rs-about.style4 .about-content .title {
        font-size: 35px;
    }

    .rs-about.style4 .about-content .book-part {
        position: relative;
        right: unset;
        bottom: unset;
        width: auto;
        margin-top: 40px;
    }

    .rs-about.style4 .about-content .book-part .single-book {
        padding: 10px 40px;
    }

    .rs-about.style5 .about-content .title br {
        display: none;
    }

    .rs-about.style5 .about-content .contact-part li .desc p {
        font-size: 20px;
    }

    .rs-about.style2 .about-intro {
        padding: 60px 25px 70px;
    }

    .rs-about.style2 .about-intro .title {
        font-size: 30px;
    }

    .rs-about.style2 .couter-area .counter-item,
    .rs-about.style3 .couter-area .counter-item {
        padding: 30px 10px;
    }

    .rs-about.style2 .couter-area .counter-item .number,
    .rs-about.style3 .couter-area .counter-item .number {
        font-size: 40px;
    }

    .rs-about.style2 .couter-area .counter-item .title,
    .rs-about.style3 .couter-area .counter-item .title {
        font-size: 20px;
    }

}

/* Tablet desktop :992px to 1199px. */
@media #{$only_lg} {
    .rs-about.video-img .shape-img.left {
        left: 15px;
    }

    .rs-about.video-img .shape-img.right {
        right: 15px;
    }

}

/* Tablet desktop :991px. */
@media #{$md} {
    .rs-about.style10 .shape-icons .shape.one {
        display: none;
    }

    .rs-about.style8 .main-content .img-part {
        display: none;
    }

    .rs-about.style9 .content-part {
        padding: 60px 30px 70px 60px;
    }

    .rs-about.style9 .content-part .about-img {
        position: unset;
    }

    .rs-about.style6 .shape-animate .transparent.left {
        left: 0;
    }

    .rs-about.style6 .shape-animate .transparent.right {
        right: 75%;
        top: -148px;
    }

    .rs-about.style6 .sec-title {
        text-align: center;
    }

    .rs-about.video-img .shape-img.left {
        display: none;
    }

    .rs-about.video-img .shape-img.right {
        display: none;
    }

    .rs-about.video-img .shape-img.center {
        display: none;
    }

    .rs-about.video-img {
        padding-bottom: 70px;
    }

    .rs-about.style1 .notice-bord.style1,
    .rs-about.style1 .about-part {
        margin-top: 70px;
    }

    .rs-about.style1 .notice-bord.style1 li {
        padding: 30px;
    }

    .rs-about.style1 .histort-part {
        position: unset;
        transform: unset;
        margin-top: 30px;
    }

    .rs-about.style2 .about-intro {
        padding: 45px 35px 50px;
    }

    .rs-about.style2 .image-grid {
        text-align: center;
    }

    .rs-about.style4 .about-content .desc {
        max-width: 550px;
    }

    .rs-about.style5 .about-content .title {
        font-size: 35px;
    }

    .rs-about.video-style .about-content {
        padding: 50px;
        margin: 0;
    }
}

/* Tablet :767px. */
@media #{$sm} {
    .rs-about.style9 .content-part .sec-title3 .title {
        font-size: 23px;
        line-height: 36px;
    }

    .rs-about.style6 .shape-animate .transparent.right {
        right: 75%;
        top: -168px;
    }

    .rs-about.style4 .about-content .title {
        font-size: 35px;
    }

    .rs-about.video-style .about-content {
        padding: 30px
    }

    .rs-about.style4 .about-content .title {
        font-size: 30px;
    }

    .rs-about.style5 .about-content .contact-part li .desc a,
    .rs-about.style5 .about-content .contact-part li .desc p {
        margin: 0;
    }

    body .rs-about.style7 .sec-title2 .title {
        font-size: 28px;
        line-height: 36px;
    }
}

/* Large Mobile :575px. */
@media #{$xs} {
    .rs-about.style4 .about-content .title {
        font-size: 30px;
    }

    .rs-about.style4 .about-content .sub-title {
        font-size: 18px;
    }

    .rs-about.style4 .about-content .contact-part li {
        width: 100%;
        margin: 0 0 30px;
    }

    .rs-about.style1 .about-part {
        padding: 40px 30px 50px;
    }

    .rs-about.style1 .about-part .sign-part {
        display: block;
    }

    .rs-about.style1 .about-part .sign-part .img-part {
        padding: 0 0 30px;
    }

    .rs-about-video .video-img-part img {
        min-height: 300px;
    }

    .rs-about.style5 {
        background: #f2fafc;
    }

    .rs-about.style5 .about-content .title {
        font-size: 30px;
    }

    .rs-about.style5 .about-content .title br {
        display: none;
    }

    .rs-about.style5 .about-content .sub-title {
        font-size: 18px;
    }

    .rs-about.style5 .about-content .contact-part li .desc {
        font-size: 16px;
    }

    .rs-about.style5 .about-content .contact-part li .desc a,
    .rs-about.style5 .about-content .contact-part li .desc p,
    .rs-about.style5 .about-content .contact-part li .desc .address {
        font-size: 20px;
    }

    .rs-about.style9 .content .sl-title {
        font-size: 28px;
        line-height: 1.4;
    }

}

/* small mobile :480px. */
@media #{$mobile} {
    .rs-about.style8 .sec-title3 .title {
        font-size: 20px;
        line-height: 30px;
    }

    .rs-about.style9 .content-part {
        padding: 60px 20px 70px 20px;
    }

    .rs-about.style6 .sec-title .title {
        font-size: 25px;
    }

    .rs-about.style6 .shape-animate .transparent.right {
        display: none;
    }

    .rs-about.style6 .shape-animate .transparent.left {
        display: none;
    }

    .rs-about.video-img .media-icon .popup-videos::before {
        width: 80px;
        height: 80px;
    }

    .rs-about.style2 .about-intro {
        padding: 45px 15px 50px;
    }

    .rs-about.style1 .img-part .left-bottom {
        max-width: 60%;
    }

    .rs-about.style2 .about-intro .title {
        font-size: 25px;
    }

    .rs-about.style5 {
        background-position: center left !important;
    }

}





/* Laptop :1600px. */
@media #{$laptop} {}

/* Custom Container Width :1400px. */
@media (max-width: 1400px) {
    .rs-about.style4 .about-content .contact-part li {
        margin-right: 15px;
    }

    .rs-about.style4 .about-content .contact-part li .img-part {
        margin-right: 15px;
    }
}

/* XL Device :1366px. */
@media #{$xl} {
    .rs-about.style2 .sec-title .title {
        font-size: 34px;
    }

}

/* Lg Device :1199px. */
@media #{$lg} {
    .rs-about.style10 .shape-icons .shape.two {
        top: 14%;
        left: 13%;
    }

    .rs-about.style7 .sec-title2 .title {
        font-size: 30px;
        line-height: 40px;
    }

    .rs-about.style4 .about-content .contact-part li {
        margin-right: 10px;
    }

    .rs-about.style4 .about-content .contact-part li .desc a {
        font-size: 18px;
        margin-top: 5px;
    }

    .rs-about.style4 .about-content .title {
        font-size: 35px;
    }

    .rs-about.style4 .about-content .book-part {
        position: relative;
        right: unset;
        bottom: unset;
        width: auto;
        margin-top: 40px;
    }

    .rs-about.style4 .about-content .book-part .single-book {
        padding: 10px 40px;
    }

    .rs-about.style5 .about-content .title br {
        display: none;
    }

    .rs-about.style5 .about-content .contact-part li .desc p {
        font-size: 20px;
    }

    .rs-about.style2 .about-intro {
        padding: 60px 25px 70px;
    }

    .rs-about.style2 .about-intro .title {
        font-size: 30px;
    }

    .rs-about.style2 .couter-area .counter-item,
    .rs-about.style3 .couter-area .counter-item {
        padding: 30px 10px;
    }

    .rs-about.style2 .couter-area .counter-item .number,
    .rs-about.style3 .couter-area .counter-item .number {
        font-size: 40px;
    }

    .rs-about.style2 .couter-area .counter-item .title,
    .rs-about.style3 .couter-area .counter-item .title {
        font-size: 20px;
    }

}

/* Tablet desktop :992px to 1199px. */
@media #{$only_lg} {
    .rs-about.video-img .shape-img.left {
        left: 15px;
    }

    .rs-about.video-img .shape-img.right {
        right: 15px;
    }

}

/* Tablet desktop :991px. */
@media #{$md} {
    .rs-about.style10 .shape-icons .shape.one {
        display: none;
    }

    .rs-about.style8 .main-content .img-part {
        display: none;
    }

    .rs-about.style9 .content-part {
        padding: 60px 30px 70px 60px;
    }

    .rs-about.style9 .content-part .about-img {
        position: unset;
    }

    .rs-about.style6 .shape-animate .transparent.left {
        left: 0;
    }

    .rs-about.style6 .shape-animate .transparent.right {
        right: 75%;
        top: -148px;
    }

    .rs-about.style6 .sec-title {
        text-align: center;
    }

    .rs-about.video-img .shape-img.left {
        display: none;
    }

    .rs-about.video-img .shape-img.right {
        display: none;
    }

    .rs-about.video-img .shape-img.center {
        display: none;
    }

    .rs-about.video-img {
        padding-bottom: 70px;
    }

    .rs-about.style1 .notice-bord.style1,
    .rs-about.style1 .about-part {
        margin-top: 70px;
    }

    .rs-about.style1 .notice-bord.style1 li {
        padding: 30px;
    }

    .rs-about.style1 .histort-part {
        position: unset;
        transform: unset;
        margin-top: 30px;
    }

    .rs-about.style2 .about-intro {
        padding: 45px 35px 50px;
    }

    .rs-about.style2 .image-grid {
        text-align: center;
    }

    .rs-about.style4 .about-content .desc {
        max-width: 550px;
    }

    .rs-about.style5 .about-content .title {
        font-size: 35px;
    }

    .rs-about.video-style .about-content {
        padding: 50px;
        margin: 0;
    }

    .rs-about.video-img .shape-bg-image {
        margin-top: -140px;
    }
}

/* Tablet :767px. */
@media #{$sm} {
    .rs-about.style9 .content-part .sec-title3 .title {
        font-size: 23px;
        line-height: 36px;
    }

    .rs-about.style6 .shape-animate .transparent.right {
        right: 75%;
        top: -168px;
    }

    .rs-about.style4 .about-content .title {
        font-size: 35px;
    }

    .rs-about.video-style .about-content {
        padding: 30px
    }

    .rs-about.style4 .about-content .title {
        font-size: 30px;
    }

    .rs-about.style5 .about-content .contact-part li .desc a,
    .rs-about.style5 .about-content .contact-part li .desc p {
        margin: 0;
    }

}

/* Large Mobile :575px. */
@media #{$xs} {
    .rs-about.style4 .about-content .title {
        font-size: 30px;
    }

    .rs-about.style4 .about-content .sub-title {
        font-size: 18px;
    }

    .rs-about.style4 .about-content .contact-part li {
        width: 100%;
        margin: 0 0 30px;
    }

    .rs-about.style1 .about-part {
        padding: 40px 30px 50px;
    }

    .rs-about.style1 .about-part .sign-part {
        display: block;
    }

    .rs-about.style1 .about-part .sign-part .img-part {
        padding: 0 0 30px;
    }

    .rs-about-video .video-img-part img {
        min-height: 300px;
    }

    .rs-about.style5 {
        background: #f2fafc;
    }

    .rs-about.style5 .about-content .title {
        font-size: 30px;
    }

    .rs-about.style5 .about-content .title br {
        display: none;
    }

    .rs-about.style5 .about-content .sub-title {
        font-size: 18px;
    }

    .rs-about.style5 .about-content .contact-part li .desc {
        font-size: 16px;
    }

    .rs-about.style5 .about-content .contact-part li .desc a,
    .rs-about.style5 .about-content .contact-part li .desc p,
    .rs-about.style5 .about-content .contact-part li .desc .address {
        font-size: 20px;
    }

}

/* small mobile :480px. */
@media #{$mobile} {
    .rs-about.style8 .sec-title3 .title {
        font-size: 20px;
        line-height: 30px;
    }

    .rs-about.style9 .content-part {
        padding: 60px 20px 70px 20px;
    }

    .rs-about.style6 .sec-title .title {
        font-size: 25px;
    }

    .rs-about.style6 .shape-animate .transparent.right {
        display: none;
    }

    .rs-about.style6 .shape-animate .transparent.left {
        display: none;
    }

    .rs-about.video-img .media-icon .popup-videos::before {
        width: 80px;
        height: 80px;
    }

    .rs-about.style2 .about-intro {
        padding: 45px 15px 50px;
    }

    .rs-about.style1 .img-part .left-bottom {
        max-width: 60%;
    }

    .rs-about.style2 .about-intro .title {
        font-size: 25px;
    }

}