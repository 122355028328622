.rs-footer {


    .footer-top {
        padding: 218px 0 93px;

        .widget-title {
            color: $secondaryColor;
            font-size: 18px;
            line-height: 26px;
            font-weight: 700;
            text-transform: uppercase;
            margin-bottom: 10px;
        }

        .site-map {
            li {
                // padding-left: 15px;
                position: relative;
                margin-bottom: 11px;

                a {
                    color: $secondaryColor;

                    &:hover {
                        color: $secondaryColor;
                    }
                }

                // &:before {
                //     position: absolute;
                //     content: '';
                //     left: 0;
                //     top: 50%;
                //     transform: translateY(-50%);
                //     width: 6px;
                //     height: 6px;
                //     background: $secondaryColor;
                // }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .address-widget {
            li {
                position: relative;
                margin-bottom: 16px;

                i {
                    position: absolute;
                    color: $secondaryColor;

                    &:before {
                        color: $secondaryColor;
                    }
                }

                .desc {
                    padding-left: 35px;
                    color: $secondaryColor;

                    a {
                        display: block;
                        color: $secondaryColor;

                        &:hover {
                            color: $secondaryColor;
                        }
                    }
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }

            &.address2 {
                li {
                    i {
                        top: 5px;
                    }
                }
            }
        }

        &.no-gap {
            padding-top: 93px;
        }
    }

    .footer-bottom {
        padding: 40px 0;
        background: transparent;
        position: relative;

        .copyright {
            p {
                margin-bottom: 0;
                color: $secondaryColor;
            }

            a {
                color: $secondaryColor;
            }
        }

        .footer-logo {
            a {
                display: inline-block;

                img {
                    max-width: 190px;
                }
            }
        }

        .footer-social {
            li {
                display: inline;
                margin-right: 5px;

                a {
                    display: inline-block;
                    width: 30px;
                    height: 30px;
                    line-height: 30px;
                    border-radius: 100%;
                    background: $secondaryColor;
                    color: $secondaryColor;
                    text-align: center;

                    &:hover {
                        color: $secondaryColor;
                        background: $secondaryColor;
                    }
                }

                &:last-child {
                    margin: 0;
                }
            }
        }

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            height: 1px;
            width: 100%;
            max-width: 1240px;
            background: rgba(255, 255, 255, 0.1);
        }
    }

    .footer-top-social {
        padding: 35px 0 0;

        @media(max-width: 575px) {
            padding: 20px 0;
        }

        .social_msg {
            text-transform: uppercase;
            color: $secondaryColor;
            font-size: xx-large;
            font-weight: bold;
            font-family: $titleFont;

            @media(max-width: 575px) {
                text-align: center;
                margin-bottom: 15px;
            }
        }

        .social_icon_container {
            display: flex;
            align-items: center;

            @media(max-width: 991px) {
                justify-content: center;
            }
        }



        .social_icon {
            li {
                display: inline-block;
                width: 30px;
                height: 30px;
                border: 1px solid #fff;
                transition: 300ms background;

                >a {
                    font-size: 14px;
                    color: $whiteColor;
                    display: flex;
                    height: 30px;
                    justify-content: center;
                    align-items: center;
                }


                &:not(:first-child) {
                    margin-left: 10px;
                }

                &.facebook {
                    &:hover {
                        background: #316ff6;
                    }
                }

                &.twitter {
                    &:hover {
                        background: #1DA1F2;
                    }
                }

                &.google-plus {
                    &:hover {
                        background: #dd4b39;
                    }
                }

                &.pinterest {
                    &:hover {
                        background: #E60023;
                    }
                }

                &.linkedin {
                    &:hover {
                        background: #0077b5;
                    }
                }

                &.instagram {
                    &:hover {
                        background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
                        background: -webkit-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
                        background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
                    }
                }

                &.youtube {
                    &:hover {
                        background: #FF0000;
                    }
                }
            }
        }
    }

    .footer-center {
        .container {}

        background-color: $secondaryColor;
        color: $whiteColor;
        padding: 25px 0;


        h3,
        p {
            margin-bottom: 0;
            line-height: 1.3;
        }

        h3 {
            color: $whiteColor;
        }

        h4 {
            color: $whiteColor;
            font-size: 20px;
            margin-bottom: 0;
            margin-top: 20px;
        }

        .ctaButton {
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 20px;

            .icn {
                width: 100px;
                height: 100px;
                border-radius: 50%;
                border: 2px solid #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 40px;
                color: #fff;

                @media (max-width: 991px) {
                    width: 80px;
                    height: 80px;
                    font-size: 30px;
                }
            }

            strong {
                display: block;
                color: #fff;
                margin-top: 10px;
            }

            span {
                color: #fff;
            }
        }
    }

    .footer-container-ts {
        width: 100%;
        background: #2A1881;
        height: 8px;
    }

    .footer-container-cs {
        width: 100%;
        background: $secondaryColor2;
        height: 20px;
    }



    &.home9-style {
        .footer-top {
            padding: 35px 0 35px;
            background: #D8E7FC;

            .widget-title {
                color: #2A1881;
                font-size: 18px;
                line-height: 26px;
                font-weight: 700;
                text-transform: uppercase;
                margin-bottom: 10px;
                position: relative;

                &:before {
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: -5px;
                    height: 2px;
                    width: 40px;
                    background: $yellowColor;
                }
            }

            .site-map {
                li {
                    // padding-left: 15px;
                    position: relative;
                    margin-bottom: 4px;
                    display: block;

                    a {
                        color: $secondaryColor;

                        &:hover {
                            color: $yellowColor;
                        }
                    }

                    // &:before {
                    //     position: absolute;
                    //     content: '';
                    //     left: 0;
                    //     top: 50%;
                    //     transform: translateY(-50%);
                    //     width: 6px;
                    //     height: 6px;
                    //     background: $yellowColor;
                    // }
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            .address-widget {
                li {
                    position: relative;
                    margin-bottom: 16px;

                    i {
                        position: absolute;

                        &:before {
                            color: $yellowColor;
                        }
                    }

                    .desc {
                        padding-left: 35px;
                        color: $secondaryColor;

                        a {
                            color: $secondaryColor;

                            &:hover {
                                color: $yellowColor;
                            }
                        }
                    }

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }

            &.no-gap {
                padding-top: 93px;
            }

            .recent-post {
                display: flex;

                .post-img {
                    width: 130px;
                    float: left;
                    padding-right: 15px;

                    img {
                        border-radius: 4px;
                    }
                }

                .post-item {
                    .post-desc {
                        a {
                            font-size: 14px;
                            color: #ffffff;
                            font-weight: 400;
                            line-height: 22px;
                            text-transform: capitalize;

                            &:hover {
                                color: $yellowColor;
                            }
                        }
                    }

                    .post-date {
                        font-size: 13px;
                        color: #ffffff;
                        font-weight: 400;

                        i {
                            font-size: 13px;
                            color: $yellowColor;
                            padding-right: 5px;
                        }
                    }
                }
            }
        }

        .footer-bottom {
            padding: 20px 0;
            background: #1c335f;
            color: #fff;
            position: relative;

            .copyright {
                p {
                    margin-bottom: 0;
                    text-align: center;
                    color: $secondaryColor;
                }

                a {
                    color: $yellowColor;

                    &:hover {
                        color: $secondaryColor;
                    }
                }
            }

            .footer-logo {
                a {
                    display: inline-block;

                    img {
                        max-width: 190px;
                    }
                }
            }

            .footer-social {
                li {
                    display: inline;
                    margin-right: 5px;

                    a {
                        display: inline-block;
                        width: 30px;
                        height: 30px;
                        line-height: 30px;
                        border-radius: 100%;
                        background: $yellowColor;
                        color: $secondaryColor;
                        text-align: center;

                        &:hover {
                            color: $secondaryColor;
                            background: $secondaryColor;
                        }
                    }

                    &:last-child {
                        margin: 0;
                    }
                }
            }

            &:before {
                background: rgba(255, 255, 255, 0.1);
            }
        }

        &.main-home {

            // background: #151515;
            .footer-top {
                .widget-title {
                    &:before {
                        background: $orangeColor;
                    }
                }

                .footer-logo {
                    a {
                        display: inline-block;

                        img {
                            max-width: 190px;
                        }
                    }
                }

                .textwidget {
                    p {
                        color: $secondaryColor !important;
                    }
                }

                .footer_social {
                    li {
                        display: inline-block;
                        margin-right: 6px;
                        color: #ffffff;

                        a {
                            color: #ffffff;
                            width: auto;
                            height: auto;
                            line-height: 16px;
                            font-size: 16px;
                            margin-right: 10px;

                            &:hover {
                                color: $orangeColor;
                            }
                        }
                    }
                }

                .site-map {
                    li {
                        a {
                            color: #434141;

                            &:hover {
                                color: $orangeColor;
                            }
                        }

                        &:before {
                            background: $orangeColor;
                        }
                    }
                }

                .address-widget {
                    li {
                        i {
                            &:before {
                                color: $orangeColor;
                            }
                        }

                        .desc {
                            a {
                                &:hover {
                                    color: $orangeColor;
                                }
                            }
                        }
                    }
                }

                &.no-gap {
                    padding-top: 93px;
                }

                .recent-post {
                    .post-img {
                        img {
                            width: 80px;
                            height: 80px;
                            border-radius: 4px;
                        }
                    }

                    .post-item {
                        .post-desc {
                            a {
                                &:hover {
                                    color: $orangeColor;
                                }
                            }
                        }

                        .post-date {
                            i {
                                font-size: 13px;
                                color: $orangeColor;
                                padding-right: 5px;
                            }
                        }
                    }
                }
            }

            .footer-bottom {
                padding: 20px 0;
                background: #2A1881;
                color: #fff;
                position: relative;

                .copyright {
                    p {
                        margin-bottom: 0;
                        color: #fff;
                    }

                    a {
                        color: #fff;

                        &:hover {
                            color: $orangeColor;
                        }
                    }
                }

                .copy-right-menu {
                    li {
                        position: relative;
                        display: inline-block;
                        position: relative;
                        padding: 0px 19px 0 25px;

                        a {
                            color: #fff;

                            &:hover {
                                color: $whiteColor;
                            }
                        }

                        &:before {
                            display: block;
                            content: "";
                            position: absolute;
                            font-size: 18px;
                            background: #e6087c;
                            left: 0;
                            top: 50%;
                            width: 6px;
                            height: 6px;
                            transform: translateY(-50%);
                            border-radius: 50%;
                        }

                        &:first-child {
                            padding-left: 0;

                            &:before {
                                display: none;
                            }
                        }
                    }
                }

                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 50%;
                    transform: translateX(-50%);
                    height: 1px;
                    width: 100%;
                    max-width: 1240px;
                    background: rgba(255, 255, 255, 0.1);
                }
            }


        }


    }


}


/* Custom Container Width :1440px. */
@media (min-width: 1440px) {}

/* Laptop :1600px. */
@media #{$laptop} {}

/* XL Device :1366px. */
@media #{$xl} {
    .rs-footer .footer-bottom::before {
        max-width: 1140px;
    }

}

/* Lg Device :1199px. */
@media #{$lg} {
    .rs-footer .footer-bottom::before {
        max-width: 930px;
    }

    .rs-footer.home9-style.main-home .footer-top .recent-post .post-img img {
        height: auto;
    }
}

/* Tablet desktop :992px to 1199px. */
@media #{$only_lg} {}

/* Tablet desktop :991px. */
@media #{$md} {
    .rs-footer .footer-bottom::before {
        max-width: 690px;
    }

    .rs-footer.home9-style.main-home.home14-style .footer-top {
        padding: 80px 0 60px;
    }

    .rs-footer .footer-top,
    .rs-footer.home9-style .footer-top {
        // padding: 190px 0 60px;
    }

    .rs-footer.home9-style .footer-top .recent-post .post-img {
        width: 100px;
    }

    .rs-footer.style7 .footer-top {
        padding: 70px 0 63px;
    }

    .rs-footer.style6 .footer-top {
        padding: 70px 0 55px;
    }

    .rs-footer.style6 .footer-bottom .main-part {
        padding-top: 15px;
        padding-bottom: 0px;
    }

    .rs-footer.home9-style.main-home .footer-bottom .copy-right-menu li:first-child {
        padding-left: 0;
    }

    .rs-footer .footer-top {
        padding: 190px 0 60px;
    }

    .rs-footer .footer-top.no-gap {
        padding: 73px 0;
    }

    .rs-footer .footer-top .widget-title {
        margin-bottom: 20px;
    }

    .rs-footer.home9-style.home12-style .footer-bottom .copyright,
    .rs-footer.home9-style.home12-style .footer-bottom .copy-right-menu {
        text-align: center;
    }

    .rs-footer.home9-style.home12-style .footer-bottom .copy-right-menu li:first-child {
        padding-left: 0;
    }
}

/* Tablet :767px. */
@media #{$sm} {
    .copy-right-menu {
        display: flex;
        flex-direction: column;
        align-items: center;

        li {
            padding: 0 !important;

            &:before {
                display: none !important;
            }
        }
    }

    .social_msg {
        font-size: large !important;
    }

    .rs-footer .footer-top,
    .rs-footer.home9-style .footer-top {
        padding: 30px 0 30px;
    }

    .rs-footer.home9-style.main-home.home15 .footer-top {
        padding: 80px 0 68px;
    }

    .rs-footer .footer-bottom::before {
        max-width: 530px;
    }

    .rs-footer .footer-top .widget-title {
        font-size: 16px;
        line-height: 24px;
    }

    .rs-footer .footer-bottom {
        padding: 15px 0;
    }

    .rs-footer.style6 .footer-bottom .main-part {
        padding-top: 5px;
    }

    .rs-footer.style8.main-home .footer-top {
        padding: 64px 0;
    }

    .rs-footer .footer-bottom {
        padding: 30px 0;
    }
}

/* Large Mobile :575px. */
@media #{$xs} {}

/* small mobile :480px. */
@media #{$mobile} {}