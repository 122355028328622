.rs-slider {
    &.main-home {
        position: relative;
        padding-top: 189px;

        .slider-content {
            text-align: center;
            position: relative;
            height: calc(100vh - 160px);
            @media (max-width: 1199px) {
                height: 50vh;
            }

            .bgMedia{
                position: absolute;
                height: 100%;
                width: 100%;
                img,video{
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }

            &:before {
                content: '';
                background: #000;
                position: absolute;
                left: 0;
                height: 100%;
                width: 100%;
                z-index: 1;
                opacity: 0.4;
            }



            &.slide1 {
                background: url(../img/slider/1.jpg);
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
            }

            &.slide2 {
                background: url(../img/slider/2.jpg);
                background-size: cover;
                background-position: bottom;
                background-repeat: no-repeat;
            }

            &.slide3 {
                background: url(../img/slider/3.jpg);
                background-size: cover;
                background-position: bottom;
                background-repeat: no-repeat;
            }
        }

        .fixedBannerTitle {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 9;
            width: 90%;

            @media (max-width:991px) {
                width: 100%;
            }

            .content-part {
                max-width: 900px;
                margin: 0 auto;
                position: relative;
                z-index: 1;

                .sl-sub-title {
                    font-size: 40px;
                    // line-height: 44px;
                    font-weight: 500;
                    color: #ffffff;
                    margin-bottom: 22px;
                    text-transform: uppercase;
                    text-align: center;
                    font-family: $titleFont;

                    span {
                        color: $orangeColor;
                    }
                }

                .sl-title {
                    font-size: 70px;
                    line-height: 80px;
                    font-weight: 700;
                    color: $whiteColor;
                    margin-bottom: 30px;
                    font-family: $titleFont;
                }

                .readon {
                    &.orange-btn {
                        background: #e6087c;
                        color: #fff;
                    }
                }
            }
        }

        .owl-nav {

            .owl-next,
            .owl-prev {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: 30px;
                width: 42px;
                height: 42px;
                line-height: 42px;
                border-radius: 50%;
                background: $orangeColor;
                text-align: center;
                color: $whiteColor;
                transition: all 0.5s;
                transition-delay: 0.70s;
                opacity: 0;
                visibility: hidden;

                i {
                    &:before {
                        content: "\f138";
                        font-family: Flaticon;
                    }
                }

                &:hover {
                    background: darken($orangeColor, 10%);
                }
            }

            .owl-next {
                right: 30px;
                left: unset;

                i {
                    &:before {
                        content: "\f137";
                    }
                }
            }
        }

        &:hover {
            .owl-nav {

                .owl-next,
                .owl-prev {
                    left: 50px;
                    transition-delay: 0s;
                    visibility: visible;
                    opacity: 1;
                }

                .owl-next {
                    right: 50px;
                    left: unset;
                }
            }
        }
    }
}


/* Custom Container Width :1440px. */
@media (min-width: 1440px) {}

/* Laptop :1600px. */
@media #{$laptop} {}


/* XL Device :1366px. */
@media #{$xl} {}

/* Lg Device :1199px. */
@media #{$lg} {
    .rs-slider.main-home {
        padding-top: 66px !important;
    }
    .rs-slider.style1 .slider-content .sl-sub-title {
        font-size: 40px;
        line-height: 50px;
        margin-bottom: 15px;
    }

    .rs-slider.style1 .slider-content .sl-title {
        font-size: 75px;
        line-height: 85px;
    }
}

/* Tablet desktop :992px to 1199px. */
@media #{$only_lg} {}

/* Tablet desktop :991px. */
@media #{$md} {   

    .rs-slider.style2 .slide-part .content .title {
        font-size: 48px;
        line-height: 60px;
    }

    .rs-slider.style2 .owl-carousel .owl-stage-outer {
        margin: 0 0 -70px;
    }

    .rs-slider.main-home .slider-content .content-part .sl-title {
        font-size: 60px;
        line-height: 1.3;
    }

    .rs-slider.main-home .slider-content .content-part {
        // padding: 200px 35px 165px;
    }

    .rs-slider.style1 .slider-content {
        padding: 200px 0;
    }

    .rs-slider.style1 .slider-content .sl-sub-title {
        font-size: 35px;
        line-height: 1.2;
        margin-bottom: 10px;
    }

    .rs-slider.style1 .slider-content .sl-title {
        font-size: 70px;
        line-height: 1.2;
    }
}

/* Tablet :767px. */
@media #{$sm} {
    .rs-slider.style2 .slide-part .content .title {
        font-size: 34px;
        // line-height: 44px;
    }

    .rs-slider.main-home .slider-content .content-part {
        // padding: 175px 15px 125px;
    }

    .rs-slider.main-home .slider-content .content-part .sl-sub-title {
        font-size: 20px;
        margin-bottom: 10px;
    }

    .rs-slider.main-home .slider-content .content-part .sl-title {
        font-size: 48px;
        margin-bottom: 50px;
    }

    .rs-slider.style1 .slider-content {
        padding: 175px 0;
    }

    .rs-slider.style1 .slider-content .sl-sub-title {
        font-size: 30px;
    }

    .rs-slider.style1 .slider-content .sl-title {
        font-size: 50px;
        margin-bottom: 30px;
    }

}

/* Large Mobile :575px. */
@media #{$xs} {
    .rs-slider.style1 .slider-content .sl-sub-title {
        font-size: 25px;
    }

    .rs-slider.style1 .slider-content .sl-title {
        font-size: 40px;
        margin-bottom: 20px;
    }

    .rs-slider.main-home .slider-content .content-part .sl-sub-title {
        font-size: 18px;
    }

    .rs-slider.main-home .slider-content .content-part .sl-title {
        font-size: 35px;
        margin-bottom: 30px;
    }

    .rs-slider.style1 .slider-content .sl-sub-title {
        font-size: 20px;
    }

    .rs-slider.style1 .slider-content .sl-title {
        font-size: 33px;
    }

}

/* small mobile :480px. */
@media #{$mobile} {
    .rs-slider.main-home .slider-content .content-part .sl-sub-title {
        font-size: 16px;
    }

    .rs-slider.main-home .slider-content .content-part .sl-title {
        font-size: 30px;
        margin-bottom: 25px;
    }
}
