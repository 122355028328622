.search-modal {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: -100%;
    background: rgba(9, 10, 17, 0.80);
    z-index: 998;
    opacity: 0;
    transition: 300ms all;

    // transition: all ease 0.5s;
    .modal-content {
        position: initial;
        border: 0;
        background: transparent;
    }

    .search-block {
        background: #15172a;
        box-shadow: 0px 0px 20px 10px #23242b;
        padding: 12px;
        border-radius: 6px;
        display: flex;
        flex-direction: column;

        input {
            height: 50px;
            line-height: 50px;
            padding: 0 15px;
            background: #03060b;
            border-width: 0 0 1px 0;
            border-radius: 6px;
            border-color: rgba(255, 255, 255, 0.4);
            box-shadow: none;
            color: #ffffff;
            font-weight: 600;
            font-size: 14px;
        }

        ::-webkit-input-placeholder {
            /* Chrome/Opera/Safari */
            color: #ffffff;
            opacity: 1;
        }

        ::-moz-placeholder {
            /* Firefox 19+ */
            color: #ffffff;
            opacity: 1;
        }

        :-ms-input-placeholder {
            /* IE 10+ */
            color: #ffffff;
            opacity: 1;
        }

        :-moz-placeholder {
            /* Firefox 18- */
            color: #ffffff;
            opacity: 1;
        }

        .search-results-lists {
            flex: 1 1;
            max-height: 60vh;
            overflow: auto;

            &::-webkit-scrollbar {
                width: 10px;
            }

            /* Track */
            &::-webkit-scrollbar-track {
                background: #f1f1f1;
            }

            /* Handle */
            &::-webkit-scrollbar-thumb {
                background: #888;
            }

            /* Handle on hover */
            &::-webkit-scrollbar-thumb:hover {
                background: #555;
            }

            ul {
                list-style: none;

                li {
                    padding-top: 10px;

                    a {
                        padding: 12px;
                        border-radius: 4px;
                        background: #03060b;
                        display: block;

                        &:hover {
                            background: $purpleColor;
                        }

                        .result-title {
                            color: $whiteColor;
                            font-weight: 800;
                            margin-bottom: 2px;
                        }

                        .result-description {
                            color: $whiteColor;
                            font-weight: 500;
                            font-size: 12px;
                            display: -webkit-box;
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;
                            overflow: hidden;
                        }
                    }

                }
            }
        }
    }

    .close {
        position: absolute;
        right: 50px;
        top: 50px;
        background: #ffffff;
        color: #0a0a0a;
        width: 40px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        cursor: pointer;
        border-radius: 50%;
        transition: all ease .5s;
        font-size: 18px;
        z-index: 9;

        img {
            height: 15px;
            transition: all ease 0.5s;
        }

        &:hover {
            img {
                transform: rotate(180deg);
            }
        }

        @media (max-width: 575px) {
            right: 20px;
            top: 20px;
        }
    }
}

body {
    &.modal-open {
        .search-modal {
            top: 0;
            opacity: 1;
            position: fixed;

            .close {
                position: fixed;
            }
        }
    }
}