.breadcrumb-bg {
    background: #D8E7FC;
    position: relative;
    padding: 12px 0;

    .breadcrumbs-text {

        .page-title {
            font-size: 48px;
            margin-bottom: 20px;
            color: $orangeColor;
        }

        ul {
            padding: 0;

            li {
                color: $navColor;
                display: inline-block;
                font-size: 16px;
                font-weight: 600;

                @media (max-width: 767px) {
                    font-size: 12px;
                }

                a {
                    position: relative;
                    padding-right: 30px;

                    @media (max-width: 767px) {
                        padding-right: 18px;
                    }

                    -webkit-transition: 0.3s;
                    transition: 0.3s;
                    color: $navColor;

                    &:before {
                        color: $navColor;
                        content: "\f054";
                        font-weight: 300;
                        font-family: "Font Awesome 6 Pro";
                        position: absolute;

                        @media (max-width: 767px) {
                            height: 12px;
                            right: 8px !important;
                        }
                    }

                    &:before {
                        right: 13px;
                    }

                    &:hover {
                        color: $orangeColor;
                    }
                }
            }
        }

        &.white-color {
            .page-title {
                color: $whiteColor;
            }

            ul {
                li {
                    color: $navColor;

                    a {
                        color: $navColor;

                        &:before,
                        &:after {
                            color: $navColor;
                        }

                        &:before {
                            right: 13px;
                        }

                        &:hover {
                            color: $orangeColor;
                        }
                    }
                }
            }
        }

        &.padding {
            padding-top: 100px;
        }
    }
}

.breadcrumbs-overlay {
    &:after {
        content: '';
        position: absolute;
        background-color: rgba(17, 17, 17, 0.8);
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
    }
}

.breadcrumbs1 {
    background-size: cover;
    background-position: center;
    background-position: center top;
}

@media #{$md} {
    .rs-breadcrumbs .breadcrumbs-img img {
        min-height: 200px;
    }
}

@media #{$sm} {
    .rs-breadcrumbs .breadcrumbs-inner .page-title {
        font-size: 30px;
        line-height: 40px;
    }

    .rs-breadcrumbs .breadcrumbs-text .page-title {
        font-size: 40px;
        margin-bottom: 5px;
    }
}

@media #{$xs} {
    .rs-breadcrumbs .breadcrumbs-img img {
        min-height: 180px;
    }
}

@media #{$mobile} {
    .rs-breadcrumbs .breadcrumbs-inner .page-title {
        font-size: 25px;
        line-height: 30px;
    }

    blockquote {
        padding: 30px 30px 1px;
    }

    .rs-breadcrumbs .breadcrumbs-text .page-title {
        font-size: 30px;
    }

    .rs-breadcrumbs .breadcrumbs-img img {
        min-height: 150px;
    }
}